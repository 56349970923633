export class RegistrationMetadata {
  title?: string
  url?: string
  registrationId?: string
  registrationDate?: string
  constructor(data: {
    title?: string
    url?: string
    registrationId?: string
    registrationDate?: string
  }) {
    this.title = data.title
    this.url = data.url
    this.registrationId = data.registrationId
    this.registrationDate = data.registrationDate
  }
}
