<template>
  <div class="relative" :class="[isExpanded ? 'h-full' : 'h-32']">
    <div class="h-full">
      <editor-content
        :editor="editor"
        class="h-full bg-transparent"
        @keydown.esc="reset"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import HardBreak from '@tiptap/extension-hard-break'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import { Editor, EditorContent, useEditor } from '@tiptap/vue-3'
import Text from '@tiptap/extension-text'
import { Ref, onMounted, ref, watch, watchEffect } from 'vue'
const props = defineProps<{
  modelValue: string
  isExpanded: boolean
  isReviewReadonly: boolean
}>()

const emit = defineEmits<{
  (e: 'save', value: string): void
  (e: 'update:modelValue', value: string): void
}>()

const isFocused = ref(false)
const editor = useEditor({
  editable: !props.isReviewReadonly,
  extensions: [Document, Paragraph, Text, HardBreak],
  injectCSS: false,
  onBlur: () => {
    isFocused.value = false
    save()
  },
  onFocus: () => {
    isFocused.value = true
  },
  onUpdate({ editor }) {
    emit('update:modelValue', editor.getText())
  },

  editorProps: {
    handleKeyDown: (_, event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        editor.value?.commands.setHardBreak()
        return true
      } else if (event.key === 'Enter') {
        save()
        return true
      }
      return false
    },
  },
}) as Ref<Editor>

watch(
  () => props.modelValue,
  (value) => {
    const content = editor.value?.getText()
    if (content !== value) {
      setEditorContent(value)
    }
  },
  {
    immediate: true,
  },
)
onMounted(() => {
  setEditorContent(props.modelValue)
})

function reset() {
  editor.value?.commands.blur()
  setEditorContent(props.modelValue)
}

function setEditorContent(value: string) {
  editor.value?.commands.setContent(value?.replaceAll('\n', '<br>') ?? '')
}

function save() {
  emit('save', editor.value?.getText() ?? '')
  editor.value?.commands.blur()
}

watchEffect(() => {
  if (editor.value)
    editor.value.setOptions({
      editorProps: {
        attributes: {
          class: [
            'p-2  w-full min-h-[8rem]',
            isFocused.value
              ? ' overflow-auto absolute top-0 left-0 z-10 bg-white'
              : 'h-full overflow-hidden',
            isFocused.value && props.isExpanded && 'h-full',
            isFocused.value && !props.isExpanded && 'max-h-80',
          ].join(' '),
        },
      },
    })
})
</script>
