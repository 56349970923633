export function applyHighlights(source: string, terms: string[]) {
  const yellowSearchRegex = new RegExp(
    `(${terms.map((t) => t).join('|')})`,
    'gi',
  )
  let result = source
  if (terms.length > 0) {
    result = result.replace(
      yellowSearchRegex,
      `<span class="bg-yellow-200 font-bold text-black">$1</span>`,
    )
  }
  return result
}
