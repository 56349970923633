<template>
  <div class="flex flex-col overflow-auto p-4 space-y-3 container">
    <div class="space-y-8">
      <TextInput
        ref="scopeSummary"
        v-model="summary"
        :placeholder="`Summarize the scope`"
        type="text"
        :multiline="true"
        @enter="(v) => updateSynthesisPlanSummary(v)"
        :disabled="review.isPlanReadonly.value"
      />

      <AttributesStructureBuilder v-model="synthesisPlan" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'

import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import AttributesStructureBuilder from './AttributeStructureBuilder/AttributesStructureBuilder.vue'
import { ref } from 'vue'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
const snackbar = useSnackbar()
const loading = useLoading()
const review = injectStrict(ReviewKey)
const synthesisPlan = review.synthesisPlan
const summary = ref(synthesisPlan.value.summary)

async function updateSynthesisPlanSummary(newSummary: string) {
  try {
    loading.start()
    await review.updateSynthesisPlanSummary(newSummary)

    snackbar.show(SnackbarState.SUCCESS, 'Scope summary updated successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
