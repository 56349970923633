<template>
  <div class="flex-grow h-full space-y-4 mb-6">
    <div class="flex items-center space-x-2">
      <p>Select a medical indication (e.g., diagnosis of a disease)</p>

      <InformationCircleIcon
        v-tooltip="
          'Only enabled buttons are selectable. Click to make your choice.'
        "
        class="w-5 h-5"
      />
    </div>
    <table
      class="w-full border-separate border-spacing-0.5 rounded-lg bg-gray-50"
      aria-describedby="medical background problem purpose"
    >
      <thead class="bg-white sticky top-0 z-10">
        <tr>
          <th
            class="px-4 py-3 text-left text-sm font-medium text-gray-600 w-48"
          ></th>
          <th
            v-for="(category, index) in categories"
            :key="index"
            class="px-4 py-3 text-left text-sm font-medium text-gray-600 w-32"
          >
            {{ category }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(purpose, purposeIndex) in diagnosticPurposes"
          :key="purposeIndex"
        >
          <td class="px-4 py-2 text-sm text-gray-700 bg-white w-48">
            {{ purpose }}
          </td>
          <td
            v-for="(category, categoryIndex) in categories"
            :key="categoryIndex"
            class="w-32"
          >
            <FloatingTextInput
              :placeholder="`Type ${preset.problem.toLowerCase()} name`"
              @save="
                ($event: string) => {
                  preset.problemName = $event
                }
              "
            >
              <template #activator="{ show }">
                <button
                  class="w-32 h-12 px-2 py-2 text-sm flex items-center justify-center transition-all duration-200 rounded-lg group focus:outline-none"
                  :disabled="!isSelectable(category, purpose)"
                  :class="buttonClass(category, purpose)"
                  @click.stop="[selectOption(purpose, category), show()]"
                >
                  <span v-if="isSelected(purpose, category)" class="truncate">
                    {{ preset.problemName }}
                  </span>
                  <span
                    v-else-if="isSelectable(category, purpose)"
                    class="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                  >
                    Select
                  </span>
                </button></template
              >
            </FloatingTextInput>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import FloatingTextInput from '@app/components/Global/Inputs/FloatingTextInput.vue'
import { CreateReviewKey } from '../../useCreateProjectReview'

const { preset } = injectStrict(CreateReviewKey)

type Category =
  | 'Disease'
  | 'Injury'
  | 'Disability'
  | 'Anatomy'
  | 'Physiological process/state'
  | 'Pathological process/state'

const categories = <Category[]>[
  'Disease',
  'Injury',
  'Disability',
  'Anatomy',
  'Physiological process/state',
  'Pathological process/state',
]

const diagnosticPurposes = [
  'Prevention',
  'Prediction',
  'Prognosis',
  'Diagnosis',
  'Treatment (management)',
  'Alleviation',
  'Monitoring',
  'Compensation',
  'Investigation',
  'Replacement',
  'Modification',
]

const selectableOptions: Record<Category, string[]> = {
  Disease: [
    'Prevention',
    'Prediction',
    'Prognosis',
    'Diagnosis',
    'Treatment (management)',
    'Alleviation',
    'Monitoring',
  ],
  Injury: [
    'Diagnosis',
    'Treatment (management)',
    'Compensation',
    'Alleviation',
    'Monitoring',
  ],
  Disability: [
    'Diagnosis',
    'Treatment (management)',
    'Compensation',
    'Alleviation',
    'Monitoring',
  ],
  Anatomy: ['Replacement', 'Investigation', 'Modification'],
  'Physiological process/state': [
    'Replacement',
    'Investigation',
    'Modification',
    'Monitoring',
  ],
  'Pathological process/state': [
    'Replacement',
    'Investigation',
    'Modification',
    'Monitoring',
  ],
}

function isSelected(purpose: string, category: Category): boolean {
  return preset.value?.purpose === purpose && preset.value?.problem === category
}

function selectOption(purpose: string, category: Category) {
  if (isSelectable(category, purpose)) {
    preset.value.problem = category
    preset.value.purpose = purpose
    preset.value.problemName = ''
  }
}

function isSelectable(category: Category, purpose: string): boolean {
  return selectableOptions[category]?.includes(purpose) || false
}

function buttonClass(category: Category, purpose: string) {
  if (isSelected(purpose, category)) {
    return 'bg-primary text-white ring-1 ring-primary ring-offset-1'
  } else if (isSelectable(category, purpose)) {
    return 'bg-[#DBEAFE] hover:bg-primary/40 cursor-pointer'
  } else {
    return 'bg-gray-50 text-gray-300 cursor-default'
  }
}
</script>
