<template>
  <Teleport to="#drawer">
    <TransitionRoot
      as="div"
      :show="open"
      enter="duration-200 ease-out"
      leave="duration-200 ease-in"
      enter-from="w-0"
      enter-to="w-[740px]"
      leave-from="w-[740px]"
      leave-to="w-0"
    >
      <slot />
    </TransitionRoot>
  </Teleport>
</template>

<script setup lang="ts">
import { TransitionRoot } from '@headlessui/vue'

const open = defineModel<boolean>()
</script>
