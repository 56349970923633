<template>
  <svg
    width="138"
    height="34"
    viewBox="0 0 138 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4697_1047)">
      <path
        d="M50.6945 17.2279H42.0715C42.2244 17.8944 42.6198 18.4792 43.18 18.8672C43.8161 19.2916 44.5689 19.5038 45.3316 19.4738C45.8867 19.4934 46.4404 19.4043 46.9617 19.2115C47.4359 19.0194 47.868 18.7354 48.2331 18.3755L49.9936 20.2935C49.3951 20.931 48.6639 21.4277 47.8523 21.7479C47.0408 22.0681 46.1689 22.204 45.299 22.1459C44.1185 22.1667 42.9502 21.9025 41.8922 21.3755C40.9501 20.9139 40.1585 20.1916 39.6101 19.2935C39.0692 18.3707 38.7927 17.3153 38.8114 16.2443C38.7974 15.1811 39.0676 14.1337 39.5938 13.2115C40.1126 12.3205 40.8693 11.5936 41.7781 11.1131C42.7276 10.6279 43.7776 10.375 44.8426 10.375C45.9076 10.375 46.9576 10.6279 47.9071 11.1131C48.7992 11.5783 49.536 12.2965 50.0262 13.1787C50.5625 14.1385 50.8327 15.2256 50.8086 16.3263C50.8086 16.3263 50.7271 16.6705 50.6945 17.2279ZM42.968 13.5558C42.4531 13.9977 42.1216 14.6178 42.0389 15.2935H47.6463C47.5562 14.6202 47.226 14.0026 46.7171 13.5558C46.1875 13.1258 45.5231 12.8991 44.8426 12.9164C44.1617 12.8962 43.4963 13.1232 42.968 13.5558Z"
        fill="#3C3C3C"
      />
      <path
        d="M64.0447 10.5742L59.2361 22.0496H55.8293L51.0532 10.5742H54.46L57.6061 18.3611L60.8662 10.5742H64.0447Z"
        fill="#3C3C3C"
      />
      <path
        d="M65.5604 8.44177C65.3782 8.27646 65.233 8.07397 65.1346 7.84783C65.0362 7.6217 64.9869 7.37708 64.9899 7.13027C64.9867 6.88102 65.0358 6.63388 65.1342 6.40505C65.2325 6.17623 65.3777 5.97087 65.5604 5.8024C65.9831 5.46735 66.5055 5.28516 67.0437 5.28516C67.5819 5.28516 68.1044 5.46735 68.5271 5.8024C68.7102 5.95988 68.8564 6.15623 68.9551 6.37734C69.0537 6.59844 69.1024 6.83879 69.0976 7.08109C69.1023 7.33539 69.054 7.58785 68.9558 7.82222C68.8575 8.05658 68.7114 8.26767 68.5271 8.44177C68.3293 8.62954 68.0962 8.77555 67.8414 8.87127C67.5867 8.967 67.3154 9.0105 67.0437 8.99915C66.4954 9.01839 65.9619 8.81792 65.5604 8.44177ZM65.3974 10.5729H68.6575V22.0483H65.3974V10.5729Z"
        fill="#3C3C3C"
      />
      <path
        d="M83.3607 6.23047V22.0173H80.1984V20.7059C79.758 21.2101 79.2089 21.6065 78.5931 21.865C77.9773 22.1235 77.311 22.2374 76.6449 22.1977C75.63 22.2127 74.6292 21.9582 73.7434 21.46C72.8825 20.988 72.1751 20.2765 71.7058 19.4108C71.1893 18.4563 70.9308 17.3823 70.956 16.296C70.9291 15.2147 71.1878 14.1456 71.7058 13.1977C72.1703 12.3242 72.8785 11.6062 73.7434 11.1321C74.6305 10.6388 75.6314 10.39 76.6449 10.4108C77.2783 10.3757 77.9117 10.4808 78.5004 10.7188C79.089 10.9567 79.6186 11.3216 80.0517 11.7878V6.23047H83.3607ZM79.3019 18.6075C79.8286 17.9536 80.116 17.1376 80.116 16.296C80.116 15.4545 79.8286 14.6384 79.3019 13.9845C78.7424 13.4397 77.9942 13.1351 77.2154 13.1351C76.4366 13.1351 75.6884 13.4397 75.1289 13.9845C74.5905 14.6331 74.2956 15.4511 74.2956 16.296C74.2956 17.1409 74.5905 17.959 75.1289 18.6075C75.6884 19.1523 76.4366 19.457 77.2154 19.457C77.9942 19.457 78.7424 19.1523 79.3019 18.6075Z"
        fill="#3C3C3C"
      />
      <path
        d="M97.4933 17.2294H88.9192C89.0721 17.8959 89.4675 18.4807 90.0276 18.8687C90.6637 19.2931 91.4165 19.5053 92.1793 19.4753C92.7344 19.4949 93.288 19.4058 93.8093 19.213C94.2866 19.0173 94.7233 18.7338 95.0971 18.377L96.8412 20.295C96.2441 20.9343 95.5132 21.4322 94.7013 21.7526C93.8894 22.073 93.0168 22.2078 92.1467 22.1474C90.9662 22.1682 89.7979 21.904 88.7399 21.377C87.7978 20.9154 87.0061 20.1931 86.4578 19.295C85.9168 18.3722 85.6404 17.3168 85.6591 16.2458C85.645 15.1826 85.9153 14.1352 86.4415 13.213C86.9603 12.322 87.717 11.5951 88.6258 11.1146C89.5778 10.6029 90.6436 10.3434 91.7229 10.3605C92.7778 10.3401 93.8207 10.5883 94.7547 11.0819C95.6469 11.547 96.3837 12.2652 96.8738 13.1474C97.4102 14.1072 97.6804 15.1942 97.6562 16.295C97.6562 16.295 97.5259 16.672 97.4933 17.2294ZM89.7668 13.5573C89.2519 13.9992 88.9204 14.6193 88.8377 15.295H94.4451C94.355 14.6217 94.0248 14.0041 93.5159 13.5573C92.9781 13.1425 92.3192 12.9177 91.6414 12.9177C90.9636 12.9177 90.3047 13.1425 89.7668 13.5573Z"
        fill="#3C3C3C"
      />
      <path
        d="M110.11 11.6704C110.571 12.1798 110.925 12.7782 111.149 13.4292C111.373 14.0802 111.463 14.7702 111.414 15.4574V22.0147H108.154V15.9819C108.212 15.255 108.003 14.5319 107.567 13.9492C107.357 13.7286 107.104 13.5548 106.823 13.4391C106.542 13.3233 106.24 13.2681 105.937 13.277C105.57 13.2584 105.204 13.3174 104.861 13.4501C104.519 13.5828 104.208 13.7864 103.948 14.0475C103.413 14.6929 103.145 15.5203 103.199 16.359V22.0147H99.9385V10.5393H103.084V11.8836C103.534 11.3892 104.092 11.0075 104.715 10.7688C105.394 10.5003 106.119 10.3667 106.85 10.3754C108.062 10.3676 109.23 10.8316 110.11 11.6704Z"
        fill="#3C3C3C"
      />
      <path
        d="M116.728 21.4248C115.797 20.9534 115.013 20.233 114.462 19.3429C113.928 18.4163 113.647 17.3645 113.647 16.2936C113.647 15.2228 113.928 14.171 114.462 13.2445C115.013 12.3543 115.797 11.6339 116.728 11.1625C117.737 10.6471 118.856 10.3882 119.988 10.4084C121.079 10.3796 122.158 10.6397 123.117 11.1625C123.994 11.6381 124.688 12.3937 125.09 13.31L122.531 14.7035C122.303 14.2108 121.939 13.7944 121.482 13.5037C121.025 13.2131 120.496 13.0605 119.955 13.0641C119.56 13.0525 119.167 13.1222 118.8 13.2688C118.433 13.4153 118.099 13.6358 117.82 13.9166C117.53 14.2242 117.306 14.5877 117.16 14.985C117.015 15.3823 116.951 15.8052 116.972 16.2281C116.941 17.0857 117.245 17.9215 117.82 18.5559C118.102 18.8315 118.437 19.0474 118.803 19.191C119.17 19.3346 119.562 19.4029 119.955 19.392C120.496 19.3998 121.028 19.2488 121.485 18.9577C121.943 18.6666 122.306 18.2479 122.531 17.7527L125.09 19.1625C124.673 20.0647 123.982 20.8108 123.117 21.2936C122.16 21.8222 121.08 22.0879 119.988 22.0642C118.866 22.1194 117.747 21.9 116.728 21.4248Z"
        fill="#3C3C3C"
      />
      <path
        d="M137.935 17.2279H129.328C129.481 17.8944 129.876 18.4792 130.437 18.8672C131.072 19.2937 131.825 19.5061 132.588 19.4738C133.143 19.4917 133.697 19.4027 134.218 19.2115C134.693 19.0194 135.125 18.7354 135.49 18.3755L137.25 20.2935C136.652 20.931 135.92 21.4277 135.109 21.7479C134.297 22.0681 133.425 22.204 132.556 22.1459C131.375 22.1686 130.206 21.9044 129.149 21.3755C128.204 20.9185 127.411 20.1952 126.867 19.2935C126.321 18.372 126.039 17.3168 126.052 16.2443C126.043 15.1797 126.319 14.1324 126.85 13.2115C127.369 12.3205 128.126 11.5936 129.035 11.1131C129.984 10.6279 131.034 10.375 132.099 10.375C133.164 10.375 134.214 10.6279 135.164 11.1131C136.052 11.5837 136.787 12.3005 137.283 13.1787C137.814 14.1398 138.079 15.227 138.049 16.3263C138.049 16.3263 137.984 16.6705 137.935 17.2279ZM130.225 13.5558C129.703 13.9924 129.37 14.6152 129.295 15.2935H134.903C134.814 14.6159 134.477 13.996 133.957 13.5558C133.422 13.1424 132.766 12.9182 132.091 12.9182C131.416 12.9182 130.76 13.1424 130.225 13.5558Z"
        fill="#3C3C3C"
      />
      <path
        d="M32.5031 19.9519C32.247 19.5489 31.902 19.2106 31.4949 18.9632C31.0879 18.7159 30.6297 18.5662 30.1558 18.5257H29.8787C29.2387 18.5573 28.6222 18.7778 28.1062 19.1597C27.5902 19.5417 27.1975 20.0681 26.9772 20.6732C26.4301 22.2024 25.5373 23.583 24.3691 24.706C23.1154 25.9221 21.5841 26.8103 19.9095 27.2925C18.2349 27.7747 16.4683 27.8361 14.7645 27.4716C13.0608 27.107 11.4721 26.3276 10.1377 25.2016C8.80334 24.0756 7.76416 22.6375 7.1113 21.0134C6.45844 19.3893 6.21188 17.6288 6.39323 15.8865C6.57458 14.1442 7.17828 12.4734 8.15142 11.0205C9.12457 9.56755 10.4374 8.377 11.9747 7.55331C13.512 6.72962 15.2268 6.29794 16.9687 6.29614C17.6246 6.29959 18.2791 6.35995 18.9247 6.47649C19.4794 6.55929 20.0061 6.77537 20.4601 7.10641C20.9141 7.43744 21.2821 7.87368 21.5328 8.37814C21.8262 9.31256 21.0601 10.3453 20.3429 11.0666L19.1041 12.329C18.4371 12.0104 17.707 11.8478 16.9687 11.8535C15.8711 11.8501 14.802 12.2038 13.9208 12.8618C13.0396 13.5198 12.3938 14.4468 12.0796 15.5044C11.7655 16.5621 11.8 17.6935 12.178 18.7298C12.556 19.766 13.2571 20.6514 14.1768 21.2539C15.0964 21.8564 16.1851 22.1435 17.2804 22.0724C18.3756 22.0013 19.4186 21.5758 20.2535 20.8594C21.0885 20.143 21.6705 19.1741 21.9129 18.0976C22.1552 17.021 22.0448 15.8945 21.598 14.8863L28.1182 8.32896C28.6127 7.74052 28.8632 6.98329 28.8177 6.21419C28.7721 5.4451 28.4341 4.72307 27.8737 4.19778C24.8442 1.51472 20.9402 0.0445516 16.9035 0.0666518C14.1587 0.0569864 11.4527 0.719042 9.01908 1.99571C6.58543 3.27238 4.49716 5.12534 2.9344 7.39472C1.37164 9.66411 0.38133 12.2818 0.0488802 15.0219C-0.28357 17.7621 0.0518552 20.5425 1.02617 23.1232C2.00049 25.7039 3.58443 28.0075 5.64143 29.8352C7.69843 31.663 10.1667 32.9599 12.8333 33.6143C15.4999 34.2687 18.2847 34.2608 20.9476 33.5913C23.6105 32.9218 26.0715 31.611 28.1182 29.7716L28.8355 29.0994C30.6077 27.3162 31.9659 25.161 32.8127 22.788C32.9843 22.3237 33.0456 21.8254 32.9919 21.333C32.9381 20.8407 32.7707 20.3677 32.5031 19.9519Z"
        fill="#2C3795"
      />
    </g>
    <defs>
      <clipPath id="clip0_4697_1047">
        <rect width="138" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
