<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-lb"
    viewBox="0 0 512 512"
  >
    <defs>
      <clipPath id="lb-a">
        <path fill-opacity=".7" d="M124 0h496v496H124z" />
      </clipPath>
    </defs>
    <g clip-path="url(#lb-a)" transform="translate(-128)scale(1.0321)">
      <g fill-rule="evenodd" stroke-width="1pt">
        <path fill="#EE161F" d="M0 372h744v124H0zM0 0h744v124H0z" />
        <path fill="#fff" d="M0 124h744v248H0z" />
      </g>
      <path
        fill="#00A850"
        d="M368.3 125.9c-7.6 15.1-12.7 15.1-25.3 25.2-5 5-12.6 7.5-2.5 12.6-10 5-15.1 7.5-20.2 17.6l2.6 2.5s9.5-4.7 10-2.5c1.7 2-12.6 9.7-14.4 11s-10.8 6.6-10.8 6.6c-12.6 10.1-20.2 7.6-27.7 22.7l25.2-2.5c5 17.6-12.6 20.1-25.2 27.7l-20.2 12.6c5 17.6 20.2 7.5 32.8 2.5l2.5 2.5v5L270 282s-29.8 17-30.3 17.6c-.2 1 0 5 0 5 10.1 2.6 25.2 5.1 35.3 0 12.6-5 15.2-10 30.3-10a97.4 97.4 0 0 1-50.5 20.2v10c15.2 0 25.3 0 37.9-2.5l32.8-10c7.5 0 15.1 7.5 12.6 15-7.6 27.7-37.8 22.7-48 45.4l40.4-15.1c10.1-5 20.2-10.1 32.8-7.6 15.1 5 15.1 15.1 35.3 20.1l-5-12.5c5 2.5 10 2.5 15.1 5 12.6 5 15.1 10 30.3 7.5-12.6-15-15.2-12.5-25.2-22.6-10.1-15.1-15.2-37.8 0-40.3l17.6 5c17.7 2.6 17.7-2.5 42.9 7.6 15.1 5 20.2 12.6 37.8 7.5-7.5-17.6-35.3-30.2-53-35.2 20.2-12.6 15.2 5 43-2.5v-5c-20.2-15.2-27.8-27.8-55.6-27.8l43-5v-5S447 235.7 446 235.3a6.3 6.3 0 0 1 4.1-4.4c8 5.2 32.3 4.6 33.7 4.5-.7-6.2-12.2-11.3-22.3-16.3 0 0-43.2-26.7-43.4-29 .8-6.8 17.7 1 35.8 6.3-5-10-15.1-15.1-25.2-17.6l15.1-2.5c-10-22.7-35.3-20.2-50.4-30.3-10.1-7.5-10.1-12.6-25.2-20.1z"
      />
      <path
        fill="#fff"
        fill-rule="evenodd"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3.1"
        d="M341 293.6c1.9-6 4.3-11.3-6.9-16.4-11.2-5.1 5.6 20.5 7 16.4zm13.3-12c-2.3.3-3.4 8.6 1 10.8 5.1.8 1-10.7-1-10.8m13.1-.9c-2.3.7-2.4 12.4 5.8 10.3 8.3-2 0-11.2-5.8-10.2zm15.3-29.8c1.8-2.8-.1-14.5-7.2-9.6-7.1 5 5 10.4 7.2 9.6m-15.5-9.7c2.2-.8 2.4-8-3.8-6-6.2 1.8 2.2 7.5 3.8 6m-14 2.9s-4.3-6-7.7-4.8c-4.2 4 8 4.9 7.7 4.8m-48 19.1c1.8.2 15.5-2.2 20.2-7.5 4.8-5.3-24.3 2.2-24.3 2.3s2.7 4.7 4 5.2zm136.7-33.8c.7-1.3-7.3-7-12-4.7-1.2 4.2 12 5.5 12 4.7M415 215.8c1.5-2.1-3.5-11-13.3-6s10.3 9.5 13.3 6m-31.1-5.2s2.4-8 8.4-6.4c6.6 5.1-8.1 6.7-8.4 6.4m-5.8-6c-1-2.2-7.1-.9-14.2 3.8-7.1 4.6 16.3 1.3 14.2-3.8m18-21.4s6.4-2.9 8.3 0c2.6 4.2-8.3 0-8.2 0zm-5.2-2.8c-1.3-2.5-8.3-2.8-8.1 1-1.2 2.8 9 2.3 8-1zm-16.7 0c-.7-1.5-10.6 0-13.6 6 4.8 2.3 15.8-2.2 13.6-6m-21.3 6.1s-13 8-13.9 13.9c.4 5 16.3-9.2 16.3-9.2s1.4-5.6-2.4-4.7m-14.5-7.3c.4-1.6 6.5-5.4 7-5 .5 1.6-5 6-7 5m4.2 30.6c.3-2.3-15.6-2.1-9.6 5.1 5 6.1 10.7-4 9.6-5zM328 220.3c-.8-1.6-2-5.9-4.2-6.3-1.7 0-11.3 2-12 3.5-.4 1.3 4 9.2 5.4 9.4 1.7.6 10.5-5.8 10.8-6.6m85.6 53.4c.5-1.7 16.7-7.3 22.3-2 6.6 9-22.7 4.8-22.3 2m44.6 10.6c3.5-6-11-13.2-17-6.3 2 8.2 14.1 11.2 17 6.3"
      />
    </g>
  </svg>
</template>
