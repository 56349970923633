import { StudyState } from '@app/types'
import { Review } from '@core/domain/models/review.model'
import { FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE } from '@core/domain/types/full-text-cannot-be-retrieved-screening-criterion.type'
import { computed, ref } from 'vue'

export default () => {
  const filters = ref<{ [key in StudyState]: boolean }>({
    [StudyState.DUPLICATE]: true,
    [StudyState.EXCLUDED]: true,
    [StudyState.FAVOURITE]: true,
    [StudyState.INCLUDED]: true,
    [StudyState.UNSCREENED]: true,
    [StudyState.QUARANTINED]: true,
  })

  const titleAndAbstractExclusionCriteriaFilter = ref<{
    [key: string]: boolean
  }>({})
  const fullTextExclusionCriteriaFilter = ref<{ [key: string]: boolean }>({})
  const importSourceFilters = ref<{ [key: string]: boolean }>({})
  const searchFilters = ref<{ [key: string]: boolean }>({})

  const query = ref<string>('')

  const highlightOnly = ref(false)
  function init(review: Review) {
    _buildStep1ExclusionCriteriaFilter(review)
    _buildStep2ExclusionCriteriaFilter(review)
    _buildImportSourcesFilters(review)
    _buildSearchFilters(review)
  }
  const terms = computed<string[]>(() => {
    return query.value
      .split(';')
      .map((st) =>
        st
          .trim()
          .toLowerCase()
          .replaceAll(/[\\.+*?^$[\](){}/'#:=!|]/gi, '\\$&'),
      )
      .filter((st) => !!st)
  })

  const hasFiltersApplied = computed(() => {
    return Object.values(filters.value).some((isApplied) => !isApplied)
  })

  function _buildStep1ExclusionCriteriaFilter(review: Review): void {
    titleAndAbstractExclusionCriteriaFilter.value =
      review.plan?.screeningPlan?.titleAndAbstractCriteria?.reduce(
        (c1f, c) => ({
          ...c1f,
          [c]: true,
        }),
        {},
      ) ?? {}
  }

  function _buildStep2ExclusionCriteriaFilter(review: Review): void {
    fullTextExclusionCriteriaFilter.value =
      review?.plan?.screeningPlan?.fullTextCriteria?.reduce(
        (c1f, c) => ({
          ...c1f,
          [c]: true,
        }),
        { [FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE]: true },
      ) ?? { [FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE]: true }
  }

  function _buildImportSourcesFilters(review: Review): void {
    const usedImportSources = Object.keys(review.stats.sources).filter(
      (k) => review.stats.sources[k].total > 0,
    )
    importSourceFilters.value =
      usedImportSources?.reduce(
        (isf, c) => ({
          ...isf,
          [c]: true,
        }),
        {},
      ) ?? {}
  }

  function _buildSearchFilters(review: Review): void {
    const usedSearches = Object.keys(review.stats.searches).filter(
      (k) => review.stats.searches[k].total > 0,
    )
    searchFilters.value =
      usedSearches?.reduce(
        (iff, c) => ({
          ...iff,
          [c]: true,
        }),
        {},
      ) ?? {}
  }

  function toggleStateFilter(
    filterName: StudyState,
    value: boolean | null = null,
  ) {
    filters.value[filterName] = value ?? !filters.value[filterName]
  }

  function toggleAllStateFilters() {
    const isEveryFilterActive = Object.keys(filters.value).every(
      (k) => filters.value[k as StudyState],
    )
    Object.keys(filters.value).forEach((filter) =>
      toggleStateFilter(filter as StudyState, !isEveryFilterActive),
    )
  }

  function toggleStepOneEcxlusionCriteriaFilter(criteriaName: string) {
    titleAndAbstractExclusionCriteriaFilter.value[criteriaName] =
      !titleAndAbstractExclusionCriteriaFilter.value[criteriaName]
  }

  function toggleStepTwoEcxlusionCriteriaFilter(criteriaName: string) {
    fullTextExclusionCriteriaFilter.value[criteriaName] =
      !fullTextExclusionCriteriaFilter.value[criteriaName]
  }

  function toggleImportSourceFilter(sourceName: string) {
    importSourceFilters.value[sourceName] =
      !importSourceFilters.value[sourceName]
  }

  function toggleSearchFilter(searchName: string) {
    searchFilters.value[searchName] = !searchFilters.value[searchName]
  }

  function toggleAllStepOneEcxlusionCriteriaFilters() {
    const isEveryFilterActive = Object.keys(
      titleAndAbstractExclusionCriteriaFilter.value,
    ).every((k) => titleAndAbstractExclusionCriteriaFilter.value[k])
    Object.keys(titleAndAbstractExclusionCriteriaFilter.value).forEach(
      (filter) => {
        titleAndAbstractExclusionCriteriaFilter.value[filter] =
          !isEveryFilterActive
      },
    )
  }

  function toggleAllStepTwoEcxlusionCriteriaFilters() {
    const isEveryFilterActive = Object.keys(
      fullTextExclusionCriteriaFilter.value,
    ).every((k) => fullTextExclusionCriteriaFilter.value[k])
    Object.keys(fullTextExclusionCriteriaFilter.value).forEach((filter) => {
      fullTextExclusionCriteriaFilter.value[filter] = !isEveryFilterActive
    })
  }

  function filterStudiesByState(studySate: StudyState) {
    filters.value[studySate] = true
    Object.keys(filters.value).forEach((filter) => {
      if (filter !== studySate) filters.value[filter as StudyState] = false
    })
  }

  function resetFilters() {
    Object.keys(filters.value).forEach((filter) => {
      filters.value[filter as StudyState] = true
    })

    Object.keys(importSourceFilters.value).forEach((importSourceFilter) => {
      importSourceFilters.value[importSourceFilter] = true
    })

    Object.keys(searchFilters.value).forEach((searchFilter) => {
      searchFilters.value[searchFilter] = true
    })
  }

  function findSearchSource(review: Review, searchName: string): string {
    const searchIndex = parseInt(searchName.replace('search ', '')) - 1
    return review.searches?.[searchIndex]?.source.name ?? ''
  }

  function findSearchQuery(review: Review, searchName: string): string {
    const searchIndex = parseInt(searchName.replace('search ', '')) - 1
    const search = review.searches?.[searchIndex]
    return search?.searchQuery ?? ''
  }

  return {
    toggleAllStateFilters,
    toggleStepOneEcxlusionCriteriaFilter,
    toggleStepTwoEcxlusionCriteriaFilter,
    toggleImportSourceFilter,
    toggleSearchFilter,
    toggleAllStepOneEcxlusionCriteriaFilters,
    toggleAllStepTwoEcxlusionCriteriaFilters,
    toggleStateFilter,
    filterStudiesByState,
    resetFilters,
    titleAndAbstractExclusionCriteriaFilter,
    fullTextExclusionCriteriaFilter,
    importSourceFilters: importSourceFilters,
    searchFilters,
    filters,
    terms,
    query,
    highlightOnly,
    init,
    findSearchSource,
    findSearchQuery,
    hasFiltersApplied,
  }
}
