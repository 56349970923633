<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M46.4613 28.0465C46.1026 27.4781 45.6194 27.0009 45.0492 26.6521C44.479 26.3032 43.8372 26.0921 43.1735 26.0349H42.7853C41.8889 26.0795 41.0254 26.3905 40.3025 26.9292C39.5797 27.4679 39.0297 28.2103 38.7211 29.0638C37.9548 31.2206 36.7042 33.1678 35.0679 34.7516C33.3118 36.4669 31.1669 37.7195 28.8213 38.3996C26.4756 39.0797 24.0011 39.1664 21.6146 38.6522C19.2282 38.1381 17.0028 37.0388 15.1337 35.4506C13.2647 33.8625 11.8091 31.8342 10.8946 29.5436C9.98011 27.2529 9.63474 24.77 9.88877 22.3127C10.1428 19.8554 10.9884 17.4988 12.3515 15.4496C13.7146 13.4004 15.5535 11.7213 17.7068 10.5595C19.8601 9.39779 22.2621 8.78894 24.702 8.78641C25.6208 8.79128 26.5375 8.87641 27.4419 9.04078C28.2189 9.15756 28.9566 9.46232 29.5925 9.92921C30.2285 10.3961 30.7439 11.0114 31.0951 11.7229C31.5061 13.0408 30.4329 14.4974 29.4283 15.5147L27.6931 17.2951C26.7588 16.8458 25.7362 16.6165 24.702 16.6246C23.1647 16.6197 21.6671 17.1185 20.4328 18.0466C19.1985 18.9746 18.2938 20.2821 17.8538 21.7738C17.4139 23.2654 17.4622 24.8612 17.9916 26.3228C18.521 27.7843 19.5032 29.0331 20.7913 29.8828C22.0794 30.7325 23.6044 31.1375 25.1386 31.0372C26.6727 30.9369 28.1336 30.3368 29.3032 29.3264C30.4727 28.316 31.288 26.9495 31.6274 25.4312C31.9669 23.9128 31.8122 22.324 31.1864 20.902L40.3194 11.6535C41.0121 10.8236 41.3629 9.75556 41.2991 8.67083C41.2353 7.5861 40.7619 6.56775 39.9769 5.82689C35.7334 2.0427 30.2649 -0.0308238 24.6107 0.000346379C20.766 -0.0132857 16.9757 0.920477 13.5668 2.72109C10.158 4.52171 7.2329 7.13511 5.04392 10.3358C2.85493 13.5366 1.46778 17.2285 1.00212 21.0932C0.536447 24.9579 1.00628 28.8794 2.37103 32.5192C3.73578 36.1591 5.95443 39.4081 8.83571 41.9859C11.717 44.5638 15.1743 46.393 18.9095 47.3159C22.6446 48.2389 26.5454 48.2277 30.2754 47.2835C34.0053 46.3393 37.4525 44.4904 40.3194 41.8962L41.324 40.9481C43.8065 38.4331 45.7089 35.3934 46.8951 32.0465C47.1354 31.3916 47.2213 30.6889 47.146 29.9945C47.0707 29.3 46.8363 28.6329 46.4613 28.0465Z"
      fill="#2C3795"
    />
  </svg>
</template>
