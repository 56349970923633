<template>
  <FloatingMenu :offset="[0, 10]" @hide="reset">
    <template #activator="{ show }">
      <slot
        name="activator"
        :show="
          () => {
            show()
            select()
          }
        "
      />
    </template>
    <template #content="{ hide }">
      <input
        ref="input"
        v-model="internalValue"
        class="w-96 evidence-input"
        type="text"
        :placeholder="placeholder"
        @keydown.enter="
          () => {
            save()
            hide()
          }
        "
      />
    </template>
  </FloatingMenu>
</template>
<script lang="ts" setup>
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import { ref, watchEffect } from 'vue'

const input = ref<HTMLInputElement>()
const props = defineProps<{
  value?: string
  placeholder?: string
}>()

const emit = defineEmits<(e: 'save', value: string) => void>()
const internalValue = ref<string>('')

watchEffect(() => (internalValue.value = props.value ?? ''))

function reset() {
  setTimeout(() => (internalValue.value = props.value ?? ''), 300)
}

function save() {
  emit('save', internalValue.value)
}

function select() {
  input.value?.focus({
    preventScroll: true,
  })
}
</script>
