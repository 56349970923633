<template>
  <Drawer :model-value="isOpen">
    <div class="sticky top-0 left-0 pt-4 px-4">
      <div class="flex items-center justify-between">
        <div class="flex gap-2 items-center">
          <button
            v-for="item in chartData"
            :key="item.id"
            class="flex items-center text-xs gap-1 pl-2 pr-3 py-1.5 rounded-full leading-none"
            :class="[
              item.badgeClasses,
              {
                'ring-blue-400 ring': selectedItem === item.id,
              },
            ]"
            @click="applyFilter(item.id)"
          >
            <div class="flex items-center gap-1">
              <div class="rounded-full bg-white/40 p-1.5">
                {{ item.value }}
              </div>
              {{ item.label }}
            </div>
          </button>
          <button
            class="flex items-center text-xs gap-1 pl-2 pr-3 py-1.5 rounded-full leading-none bg-blue-200 text-blue-600"
            :class="[
              {
                'ring-blue-400 ring': selectedItem === null,
              },
            ]"
            @click="clearSelection()"
          >
            <div class="flex items-center gap-1">
              <div class="rounded-full bg-white/40 p-1.5">
                {{ review.entity?.value?.stats?.overall?.total ?? 0 }}
              </div>
              All
            </div>
          </button>
        </div>
        <button @click="closeDrawer"><XMarkIcon class="w-5" /></button>
      </div>
      <div
        class="border rounded-lg bg-slate-50 border-black/20 mt-4 h-full w-full"
      >
        <PieChart
          :selected="selectedItem"
          :items="chartData"
          @update:selected="applyFilter"
        />
      </div>
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import PieChart from '@app/components/PieChart.vue'
import { injectStrict } from '@app/utils/injectStrict'

import { StudyState } from '@app/types'
import { ReviewKey } from '../use-review'
import Drawer from '@app/components/Global/Drawer.vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const isOpen = defineModel<boolean>()

function closeDrawer() {
  isOpen.value = false
}

const review = injectStrict(ReviewKey)

const selectedItem = computed<StudyState | null>(() => {
  if (!review.filtering.hasFiltersApplied.value) {
    return null
  }
  return Object.keys(review.filtering.filters.value).find(
    (key) => review.filtering.filters.value[key as StudyState],
  ) as StudyState
})

const chartData = computed(() => [
  {
    label: 'Included',
    value: review.entity.value.stats.overall.included ?? 0,
    color: '#4ade80',
    calloutColor: '#16a34a',
    badgeClasses: 'bg-green-200 text-green-800',
    id: StudyState.INCLUDED,

    order: 1,
  },
  {
    label: 'Excluded',
    value:
      (review.entity.value.stats.overall
        .titleAndAbstractExcludedScreeningTotal ?? 0) +
      (review.entity.value.stats.overall.fullTextExcludedScreeningTotal ?? 0),
    color: '#f87171',
    calloutColor: '#dc2626',
    badgeClasses: 'bg-red-200 text-red-800',
    id: StudyState.EXCLUDED,
    order: 2,
  },
  {
    label: 'Duplicates',
    value: review.entity.value.stats.overall.duplicate ?? 0,
    color: '#c084fc',
    calloutColor: '#9333ea',
    badgeClasses: 'bg-purple-200 text-purple-800',
    id: StudyState.DUPLICATE,
    order: 3,
  },
  {
    label: 'Quarantined',
    value: review.entity.value.stats.overall.quarantined ?? 0,
    color: 'url(#quarantine-stripes)',
    badgeClasses: 'bg-yellow-200 text-yellow-800',
    calloutColor: '#ca8a04',
    id: StudyState.QUARANTINED,
    order: 4,
  },
  {
    label: 'Unscreened',
    value: review.entity.value.stats.overall.unscreened ?? 0,
    color: '#e5e7eb',
    calloutColor: '#475569',
    badgeClasses: 'bg-slate-200 text-slate-800',
    id: StudyState.UNSCREENED,
    order: 5,
  },
])

function applyFilter(id: StudyState | null) {
  if (id === null) {
    clearSelection()
  } else {
    setFilter(id)
  }
}

function clearSelection() {
  review.filtering.resetFilters()
}

function setFilter(filter: StudyState) {
  review.filtering.filterStudiesByState(filter)
}
</script>
