import { Api } from '@infrastructure/datasource/review-manager-api'

export class SettingsService {
  constructor(private _reviewManagerService: Api<unknown>) {}
  async findOpenAiKey(): Promise<string> {
    const { data } =
      await this._reviewManagerService.api.settingsControllerGetOpenAiKey()
    return data
  }
  async configureAiAssistant(apiKey: string): Promise<void> {
    await this._reviewManagerService.api.studiesControllerConfigureStudiesQa({
      apiKey,
    })
  }

  async getAssistant(): Promise<{ model: string; id: '' }> {
    const { data } =
      await this._reviewManagerService.api.settingsControllerGetAssistant()
    return data as unknown as { model: string; id: '' }
  }

  async updateAssistantModel(model: string): Promise<void> {
    await this._reviewManagerService.api.settingsControllerUpdateAssistantModel(
      {
        model,
      },
    )
  }
}
