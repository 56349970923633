<template>
  <div class="w-full h-full">
    <Scope v-lazy-show="activeStep === Step.scope" />

    <Evaluators
      v-if="isCuttingEdge"
      v-lazy-show="activeStep === Step.evaluators"
    />
    <EligibilityCriteria
      v-lazy-show="activeStep === Step.eligibilityCriteria"
    />
    <Appraisal v-lazy-show="activeStep === Step.appraisal" />
    <PlanImportSources
      v-lazy-show="activeStep === Step.importSources"
      :readonly="review.isPlanReadonly.value"
    />
  </div>
</template>

<script lang="ts" setup>
import EligibilityCriteria from './EligibilityCriteria.vue'
import Appraisal from './Appraisal/Appraisal.vue'
import PlanImportSources from './Searches/PlanImportSources.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'
import Evaluators from './Evaluators/Evaluators.vue'
import { DevToolboxKey } from '@app/injectionKeys'
import Scope from './Scope/Scope.vue'

const { isCuttingEdge } = injectStrict(DevToolboxKey)

enum Step {
  evaluators = 1,
  scope = 2,
  eligibilityCriteria = 3,
  appraisal = 4,
  importSources = 5,
  lock = 6,
}
withDefaults(
  defineProps<{
    activeStep?: Step
  }>(),
  {
    activeStep: 1,
  },
)
const review = injectStrict(ReviewKey)
</script>
