<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3495_17729"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3495_17729)">
      <path
        d="M1.26562 0V9.81818H4.84744L7.9929 4.36364H15.9929V0H1.26562Z"
        fill="#DB4437"
      />
      <path
        d="M1.26562 0V9.81818H4.84744L7.9929 4.36364H15.9929V0H1.26562Z"
        fill="url(#paint0_linear_3495_17729)"
      />
      <path
        d="M0 16H7.61818L11.1636 12.4545V9.81818H4.83636L0 1.5V16Z"
        fill="#0F9D58"
      />
      <path
        d="M0 16H7.61818L11.1636 12.4545V9.81818H4.83636L0 1.5V16Z"
        fill="url(#paint1_linear_3495_17729)"
      />
      <path
        d="M8.00426 4.36719L11.1497 9.82173L7.64062 16.0036H16.0043V4.36719H8.00426Z"
        fill="#FFCD40"
      />
      <path
        d="M8.00426 4.36719L11.1497 9.82173L7.64062 16.0036H16.0043V4.36719H8.00426Z"
        fill="url(#paint2_linear_3495_17729)"
      />
      <path
        d="M4.94489 9.79006L1.28125 3.67188L4.8267 9.83551L4.94489 9.79006Z"
        fill="#3E2723"
        fill-opacity="0.1"
      />
      <path
        d="M11.0808 9.9348L11.0081 9.89844L7.61719 15.9893L11.099 9.89844L11.0808 9.9348Z"
        fill="#263238"
        fill-opacity="0.1"
      />
      <path
        d="M8 4.36719V6.27628L15.1273 4.36719H8Z"
        fill="url(#paint3_radial_3495_17729)"
      />
      <path
        d="M1.26562 3.67969L6.46562 8.87969L4.84744 9.81605L1.26562 3.67969Z"
        fill="url(#paint4_radial_3495_17729)"
      />
      <path
        d="M7.61719 16.001L9.52628 8.88281L11.1445 9.81918L7.61719 16.001Z"
        fill="url(#paint5_radial_3495_17729)"
      />
      <path
        d="M7.99574 11.6399C10.004 11.6399 11.6321 10.0119 11.6321 8.00355C11.6321 5.99524 10.004 4.36719 7.99574 4.36719C5.98743 4.36719 4.35938 5.99524 4.35938 8.00355C4.35938 10.0119 5.98743 11.6399 7.99574 11.6399Z"
        fill="#F1F1F1"
      />
      <path
        d="M7.99503 10.908C9.60168 10.908 10.9041 9.60558 10.9041 7.99893C10.9041 6.39229 9.60168 5.08984 7.99503 5.08984C6.38838 5.08984 5.08594 6.39229 5.08594 7.99893C5.08594 9.60558 6.38838 10.908 7.99503 10.908Z"
        fill="#4285F4"
      />
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="url(#paint6_radial_3495_17729)"
      />
      <path
        d="M11.1455 9.81712C10.8258 10.3682 10.367 10.8256 9.81496 11.1435C9.26293 11.4615 8.63705 11.6289 8 11.6289C7.36295 11.6289 6.73707 11.4615 6.18504 11.1435C5.63301 10.8256 5.1742 10.3682 4.85455 9.81712L0 1.58984L4.85455 9.90803C5.1742 10.4591 5.63301 10.9165 6.18504 11.2344C6.73707 11.5524 7.36295 11.7198 8 11.7198C8.63705 11.7198 9.26293 11.5524 9.81496 11.2344C10.367 10.9165 10.8258 10.4591 11.1455 9.90803V9.81712Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M8.09091 4.36719H8.04545C9.00988 4.36719 9.9348 4.7503 10.6168 5.43225C11.2987 6.1142 11.6818 7.03913 11.6818 8.00355C11.6818 8.96798 11.2987 9.8929 10.6168 10.5748C9.9348 11.2568 9.00988 11.6399 8.04545 11.6399H8.09091C10.0909 11.6399 11.7273 10.0036 11.7273 8.00355C11.7273 6.00355 10.0909 4.36719 8.09091 4.36719ZM8 15.9126C10.1139 15.9127 12.1419 15.0761 13.6408 13.5856C15.1398 12.0951 15.988 10.0719 16 7.9581V8.00355C16 10.1253 15.1571 12.1601 13.6569 13.6604C12.1566 15.1607 10.1217 16.0036 8 16.0036C5.87827 16.0036 3.84344 15.1607 2.34315 13.6604C0.842855 12.1601 0 10.1253 0 8.00355V7.9581C0.0120107 10.0719 0.860165 12.0951 2.35916 13.5856C3.85815 15.0761 5.88612 15.9127 8 15.9126Z"
        fill="#3E2723"
        fill-opacity="0.1"
      />
      <path
        d="M11.1818 9.93636C11.4357 9.50073 11.5945 9.0163 11.6478 8.5149C11.701 8.01351 11.6476 7.50653 11.4909 7.02727C11.6323 7.49069 11.6745 7.97865 11.615 8.45947C11.5555 8.94029 11.3955 9.40322 11.1455 9.81818L7.72727 16L11.2 9.95455L11.1818 9.93636ZM8 0.0909091C10.1139 0.090875 12.1419 0.927487 13.6408 2.41796C15.1398 3.90844 15.988 5.93161 16 8.04545V8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8V8.04545C0.0120107 5.93161 0.860165 3.90844 2.35916 2.41796C3.85815 0.927487 5.88612 0.090875 8 0.0909091Z"
        fill="white"
        fill-opacity="0.2"
      />
      <path
        d="M7.99574 4.27344C5.99574 4.27344 4.35938 5.9098 4.35938 7.9098V8.00071C4.35938 6.00071 5.99574 4.36435 7.99574 4.36435H15.9957V4.27344H7.99574Z"
        fill="#3E2723"
        fill-opacity="0.2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3495_17729"
        x1="1.92926"
        y1="6.18182"
        x2="6.70199"
        y2="3.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A52714" stop-opacity="0.6" />
        <stop offset="0.7" stop-color="#A52714" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3495_17729"
        x1="9.35455"
        y1="14.3182"
        x2="4.04546"
        y2="11.2091"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#055524" stop-opacity="0.4" />
        <stop offset="0.3" stop-color="#055524" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3495_17729"
        x1="10.4497"
        y1="3.89446"
        x2="11.7861"
        y2="9.73992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EA6100" stop-opacity="0.3" />
        <stop offset="0.7" stop-color="#EA6100" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_3495_17729"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.65455 4.36719) scale(7.64545 7.64546)"
      >
        <stop stop-color="#3E2723" stop-opacity="0.2" />
        <stop offset="1" stop-color="#3E2723" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_3495_17729"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1.25653 3.67969) scale(7.09091)"
      >
        <stop stop-color="#3E2723" stop-opacity="0.2" />
        <stop offset="1" stop-color="#3E2723" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_3495_17729"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.98082 8.01009) scale(7.99091)"
      >
        <stop stop-color="#263238" stop-opacity="0.2" />
        <stop offset="1" stop-color="#263238" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_3495_17729"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.39091 2.18182) scale(16.0727)"
      >
        <stop stop-color="white" stop-opacity="0.1" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>
