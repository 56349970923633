import { AutoImportCompletedEvent } from '@core/domain/events/autoImportCompleted.event'
import { ReviewSearchCreatedEvent } from '@core/domain/events/reviewSearchCreated.event'
import { EventDispatcher } from '@infrastructure/eventDispatcher/eventDispatcher'
import { io } from 'socket.io-client'

export class SocketGateway {
  private socket = io('/reviews', {
    path: '/events',
  })
  constructor(private readonly eventDispatcher: EventDispatcher) {}

  init() {
    this.socket.on('reviewUpdated', ({ reviewId }: { reviewId: number }) => {
      this.eventDispatcher.dispatch(new ReviewSearchCreatedEvent(reviewId))
    })
    this.socket.on(
      'autoImportCompleted',
      ({ reviewId }: { reviewId: number }) => {
        this.eventDispatcher.dispatch(new AutoImportCompletedEvent(reviewId))
      },
    )
  }
}
