<template>
  <button
    class="gap-1 px-3 py-2 text-sm rounded flex items-center justify-center text-black"
    :class="[
      buttonStateClass,
      {
        'hover:brightness-95':
          state !== ButtonState.disabled && !isLoading && !isReviewReadOnly,
      },
    ]"
    :disabled="state === ButtonState.disabled || isLoading || isReviewReadOnly"
  >
    <slot />
    <ArrowDownIcon
      v-if="!isReviewReadOnly && arrow"
      class="w-3 h-3 fill-current"
    />
  </button>
</template>

<script lang="ts" setup>
import ArrowDownIcon from '@app/components/Icons/ArrowDownIcon.vue'
import { ButtonState } from '@app/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    state?: ButtonState
    isLoading?: boolean
    isReviewReadOnly?: boolean
    arrow?: boolean
  }>(),
  {
    state: ButtonState.inactive,
    isLoading: false,
    isReviewReadOnly: false,
    arrow: true,
  },
)
const buttonStateClass = computed(() => {
  if (props.isLoading || props.state === ButtonState.disabled)
    return 'bg-gray-100 text-gray-400 fill-gray-400'
  else if (props.state === ButtonState.red)
    return 'bg-red-200 text-red-800 fill-red-800'
  else if (props.state === ButtonState.blue) return 'bg-cyan-200 text-cyan-800'
  else if (props.state === ButtonState.violet)
    return 'bg-purple-200 text-purple-800 fill-purple-800'
  else if (props.state === ButtonState.inactive)
    return 'bg-slate-200 text-slate-800 fill-slate-800'
  else if (props.state === ButtonState.green)
    return 'bg-green-200 text-green-800 fill-green-800'
  else if (props.state === ButtonState.appraised)
    return 'bg-blue-200 text-blue-800'
  else return ''
})
</script>
