<template>
  <ExpansionPanel
    :key="sourceType"
    class="w-full"
    :model-value="expansionPanel.isShown.value"
  >
    <template #activator="{ isShown }">
      <button
        class="flex justify-between items-center w-full py-2 px-4"
        :class="[
          buttonClass,
          {
            'bg-slate-100': isShown,
          },
        ]"
        @click="togglePanel"
      >
        <div class="flex gap-2 items-center">
          <div
            class="bg-slate-300 rounded-full w-2 h-2"
            :class="{
              '!bg-primary': isShown,
            }"
          />
          <div>{{ sourceType }}</div>
        </div>
        <ChevronDownIcon v-if="isShown" class="w-4 h-4 text-primary" />
        <ChevronRightIcon v-else class="w-4 h-4 text-primary" />
      </button>
    </template>
    <template #default>
      <ReviewItemTypeCard
        v-if="sourceType === 'Scholarly articles (Aggregated)'"
        :sources="scholarlyArticlesAggregatedSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Scholarly articles (Publisher-specific)'"
        :sources="scholarlyArticlesPublisherSpecificSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Scholarly Articles (Systematic Reviews)'"
        :sources="scholarlyArticlesSystematicReviewSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Clinical trials'"
        :sources="clinicalTrialsSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      >
        <div class="p-1 pt-0 w-full">
          <ul class="ml-4 pl-2 space-y-1 tree">
            <li
              v-for="(provider, index) in clinicalTrialDataProviders"
              :key="index"
              class="sub-item"
            >
              <div class="flex items-center gap-3 flex-1">
                <component :is="provider.icon" class="rounded-full w-5 h-5" />
                <span class="text-sm line-clamp-1 w-4/5">
                  {{ provider.name }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </ReviewItemTypeCard>
      <ReviewItemTypeCard
        v-if="sourceType === 'Incident'"
        :sources="incidentSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />

      <div v-if="sourceType === 'Safety information'">
        <Modal>
          <template #activator="{ show }">
            <button
              class="flex-1 text-left w-full px-4 py-2 hover:bg-black/5"
              @click="() => (isPlanEditable ? show() : null)"
            >
              Safety information databases
            </button>
            <ul class="ml-4 pl-2 space-y-1 tree">
              <li
                v-for="(provider, index) in fieldSafetyNoticesProviders"
                :key="index"
                class="w-full sub-item flex items-center gap-3"
              >
                <component :is="provider.icon" class="rounded-full w-5 h-5" />
                <span class="text-sm line-clamp-1 w-4/5">
                  {{ provider.name }}
                </span>
              </li>
            </ul>
          </template>
          <template #content="{ hide }">
            <FsnSearch @hide="hide" />
          </template>
        </Modal>
      </div>
      <ReviewItemTypeCard
        v-if="sourceType === 'Standards'"
        :sources="standardsSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
      <ReviewItemTypeCard
        v-if="sourceType === 'Guidance'"
        :sources="guidanceSources"
        :is-plan-unlocked="isPlanEditable"
        @add-import-source="handleAddImportSource"
      />
    </template>
  </ExpansionPanel>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import ReviewItemTypeCard from '../AddImportSourceToPlan/ReviewItemTypeCard.vue'
import ExpansionPanel from '@app/components/Global/ExpansionPanel.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../../use-review'
import { DevToolboxKey } from '@app/injectionKeys'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { isUrlValid } from '@app/utils/urlValidation'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import {
  AustraliaFlag,
  ChinaFlag,
  USAFlag,
  EUFlag,
  NetherlandsFlag,
  BrazilFlag,
  IndiaFlag,
  SouthKoreaFlag,
  CubaFlag,
  GermanyFlag,
  IranFlag,
  JapanFlag,
  SouthAfricaFlag,
  SriLankaFlag,
  ThailandFlag,
  PeruFlag,
  LebanonFlag,
  UKFlag,
  CyprusFlag,
  FranceFlag,
} from '@app/components/Icons/flags/index'
import Modal from '@app/components/Global/Modal/Modal.vue'
import FsnSearch from '../FsnSearch/FsnSearch.vue'
import useExpansionPanel from '@app/composables/use-expansion-panel'

const expansionPanel = useExpansionPanel()

defineProps<{
  sourceType: string
}>()

interface DataProvider {
  name: string
  icon: any
}

const clinicalTrialDataProviders: DataProvider[] = [
  {
    name: 'Australian New Zealand Clinical Trials Registry',
    icon: AustraliaFlag,
  },
  { name: 'Chinese Clinical Trial Registry', icon: ChinaFlag },
  { name: 'ClinicalTrials.gov', icon: USAFlag },
  { name: 'Clinical Trials Information System (CTIS)', icon: EUFlag },
  { name: 'EU Clinical Trials Register (EU-CTR)', icon: EUFlag },
  { name: 'ISRCTN', icon: UKFlag },
  { name: 'The Netherlands National Trial Register', icon: NetherlandsFlag },
  { name: 'Brazilian Clinical Trials Registry (ReBec)', icon: BrazilFlag },
  { name: 'Clinical Trials Registry - India', icon: IndiaFlag },
  {
    name: 'Clinical Research Information Service - Republic of Korea',
    icon: SouthKoreaFlag,
  },
  { name: 'Cuban Public Registry of Clinical Trials', icon: CubaFlag },
  { name: 'German Clinical Trials Register', icon: GermanyFlag },
  { name: 'Iranian Registry of Clinical Trials', icon: IranFlag },
  { name: 'Japan Registry of Clinical Trials (jRCT)', icon: JapanFlag },
  { name: 'Pan African Clinical Trial Registry', icon: SouthAfricaFlag },
  { name: 'Sri Lanka Clinical Trials Registry', icon: SriLankaFlag },
  { name: 'Thai Clinical Trials Registry (TCTR)', icon: ThailandFlag },
  { name: 'Peruvian Clinical Trials Registry (REPEC)', icon: PeruFlag },
  { name: 'Lebanese Clinical Trials Registry (LBCTR)', icon: LebanonFlag },
  {
    name: 'International Traditional Medicine Clinical Trial Registry (ITMCTR)',
    icon: ChinaFlag,
  },
]

const fieldSafetyNoticesProviders: DataProvider[] = [
  { name: 'ANSM', icon: FranceFlag },
  { name: 'BFARM', icon: GermanyFlag },
  { name: 'FDA', icon: USAFlag },
  { name: 'MHRA', icon: UKFlag },
  { name: 'MOH', icon: CyprusFlag },
  { name: 'SARA', icon: AustraliaFlag },
]

const builtInImportSources = useBuiltInImportSources()
const review = injectStrict(ReviewKey)
const { isCuttingEdge } = injectStrict(DevToolboxKey)
const loading = useLoading()
const snackbar = useSnackbar()

const isPlanEditable = computed(() => !review.isPlanReadonly.value)

const scholarlyArticlesAggregatedSources = computed(() => {
  const articleSources = [
    createSource(
      BuiltInImportSourceId.PUBMED,
      '/sources-icons/pubmed.png',
      true,
    ),
    createSource(
      BuiltInImportSourceId.GOOGLE_SCHOLAR,
      '/sources-icons/google-scholar.png',
      true,
    ),
    createSource(
      BuiltInImportSourceId.OPEN_ALEX,
      '/sources-icons/openalex.png',
      true,
    ),
    createSource(
      BuiltInImportSourceId.DIMENSIONS_AI,
      '/sources-icons/dimensions-ai.png',
      true,
    ),
    createSource(BuiltInImportSourceId.PMC, '/sources-icons/pmc.png', true),
    createSource(
      BuiltInImportSourceId.EUROPE_PMC,
      '/sources-icons/europe-pmc.png',
      true,
    ),
    createSource(
      BuiltInImportSourceId.LIVIVO,
      '/sources-icons/livivo.png',
      false,
    ),
    createSource(BuiltInImportSourceId.BASE, '/sources-icons/base.png', false),
    createSource(
      BuiltInImportSourceId.EMBASE,
      '/sources-icons/science-direct.png',
      false,
    ),
  ]
  return articleSources
})

const scholarlyArticlesPublisherSpecificSources = computed(() => {
  const articleSources = [
    createSource(
      BuiltInImportSourceId.WILEY_ONLINE_LIBRARY,
      '/sources-icons/wiley-online-library.png',
      false,
    ),
    createSource(
      BuiltInImportSourceId.MAG_ONLINE,
      '/sources-icons/mag-online.png',
      false,
    ),
    createSource(
      BuiltInImportSourceId.SCIENCE_DIRECT,
      '/sources-icons/science-direct.png',
      false,
    ),
  ]
  return articleSources
})

const scholarlyArticlesSystematicReviewSources = computed(() => {
  const sources = [
    createSource(
      BuiltInImportSourceId.COCHRANE,
      '/sources-icons/cochrane.png',
      true,
    ),
    ...(isCuttingEdge
      ? [
          createSource(
            BuiltInImportSourceId.PROSPERO,
            '/sources-icons/prospero.png',
            true,
          ),
        ]
      : []),
  ]
  return sources
})

const clinicalTrialsSources = computed(() => {
  const sources = [
    createSource(BuiltInImportSourceId.ICTRP, '/sources-icons/ictrp.png', true),
  ]
  return sources
})

const incidentSources = computed(() => {
  const sources = [
    createSource(BuiltInImportSourceId.MAUDE, '/sources-icons/maude.png', true),
  ]
  return sources
})

const standardsSources = computed(() => {
  const sources = [
    createSource(
      BuiltInImportSourceId.CENELEC,
      '/sources-icons/cenelec.png',
      true,
    ),
  ]
  return sources
})

const guidanceSources = computed(() => {
  const sources = [
    createSource(BuiltInImportSourceId.MDCG, '/sources-icons/mdcg.png', true),
  ]
  return sources
})

function createSource(
  id: BuiltInImportSourceId,
  iconUrl: string,
  isEvidenceMetadataExtractionTechnology: boolean,
) {
  const source = builtInImportSources.findById(id)!
  return {
    label: source.name,
    id: source.id,
    iconUrl,
    isEvidenceMetadataExtractionTechnology,
    type: source.type,
    url: source.url,
    description: source.description,
  }
}

async function handleAddImportSource(data: {
  id: string
  name: string
  url?: string
  description?: string
  type: ImportSourceType
}) {
  if (
    data.type !== ImportSourceType.HAND_SEARCH &&
    data.type !== ImportSourceType.CITATION_SEARCH &&
    (!data.url || !isUrlValid(data.url))
  ) {
    throw new Error('Invalid URL')
  }
  loading.start()
  try {
    if (!isSourceUsedInReview(data.id)) {
      await review.addCustomImportSourceToPlan({
        id: data.id,
        name: data.name,
        url: data.url ?? '',
        description: data.description,
        type: data.type,
      })
      snackbar.show(
        SnackbarState.SUCCESS,
        'Import source successfully added to plan',
      )
    }
    document.getElementById(data.id)?.scrollIntoView({ behavior: 'smooth' })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

function isSourceUsedInReview(sourceId: string) {
  return review.entity.value.plan?.importPlan?.importSources?.some(
    (s) => s.id === sourceId,
  )
}

const buttonClass = computed(() => ({
  'hover:bg-black/5': isPlanEditable.value,
  'cursor-not-allowed': !isPlanEditable.value,
}))

function togglePanel() {
  expansionPanel.toggle()
}
</script>

<style scoped>
.tree {
  position: relative;
  padding-left: 20px;
}

.item {
  position: relative;
}

.sub-item {
  position: relative;
}

.sub-item::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: rgb(60 60 60 / 0.3);
}

.sub-item span::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  width: 20px;
  height: 1px;
  background-color: rgb(60 60 60 / 0.3);
}

.sub-item:last-child::before {
  height: 50%;
}
</style>
