<template>
  <div class="space-y-2 p-2">
    <TextInput
      v-model="newImportSource.name"
      placeholder="Enter source name"
      label="Name"
      is-required
    />
    <TextInput
      v-model="newImportSource.url"
      placeholder="Enter source url"
      label="URL"
      is-required
      :rules="[(v) => !v || isUrlValid(v) || 'Invalid URL']"
    >
      <template #suffix>
        <img
          v-show="sourceIconUrl"
          class="w-6 h-6"
          :src="sourceIconUrl"
          alt="source logo"
        />
      </template>
    </TextInput>
    <TextInput
      v-model="newImportSource.description"
      placeholder="Enter source description"
      label="Description"
    />
    <button
      class="w-full bg-primary text-white rounded-lg px-4 py-2 font-medium block text-center"
      :class="[
        canImport ? 'hover:bg-primary-dark' : 'opacity-50 cursor-not-allowed',
      ]"
      :disabled="!canImport"
      @click="addImportSource(newImportSource)"
    >
      Submit
    </button>
  </div>
</template>
<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { isUrlValid } from '@app/utils/urlValidation'
import { ReviewKey } from '@app/views/Review/use-review'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { HttpException } from '@core/exceptions/http.exception'
import { watchDebounced } from '@vueuse/core'
import { computed, ref } from 'vue'

const props = defineProps<{
  importSourceType: ImportSourceType
}>()

const loading = useLoading()
const snackbar = useSnackbar()
const review = injectStrict(ReviewKey)

type NewImportSource = {
  id: ''
  type: ImportSourceType
  name: string
  url: string
  description?: string
  order: 0
}

const newImportSource = ref<NewImportSource>({
  name: '',
  url: '',
  description: '',
  type: props.importSourceType,
  id: '',
  order: 0,
})

const sourceIconUrl = ref('')

watchDebounced(
  () => newImportSource.value.url,
  (url) => {
    if (!url || !isUrlValid(url))
      sourceIconUrl.value = '/sources-icons/custom-source.png'
    else
      sourceIconUrl.value = `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
        url,
      )}&sz=24`
  },
  {
    debounce: 500,
    maxWait: 1000,
  },
)

async function addImportSource(data: NewImportSource) {
  if (!isUrlValid(data.url)) {
    throw new Error('Invalid URL')
  }
  loading.start()
  try {
    await review.addCustomImportSourceToPlan({
      name: data.name,
      url: data.url,
      description: data.description,
      type: data.type,
    })
    snackbar.show(
      SnackbarState.SUCCESS,
      'Import source successfully added to plan',
    )
    reset()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const canImport = computed(
  () =>
    isUrlValid(newImportSource.value.url) &&
    newImportSource.value.name.length > 0,
)

function reset() {
  newImportSource.value = {
    name: '',
    url: '',
    description: '',
    type: ImportSourceType.OTHER_SOURCE,
    id: '',
    order: 0,
  }
}
</script>
