import { SortBy, SortOrder } from '@app/types'
import { ref } from 'vue'

export default () => {
  const sort = ref({ order: SortOrder.Descending, by: SortBy.None })
  function updateSort(sortBy: SortBy) {
    if (sortBy === sort.value.by) {
      sort.value.order =
        sort.value.order === SortOrder.Descending
          ? SortOrder.Ascending
          : SortOrder.Descending
    }
    sort.value.by = sortBy
  }

  return {
    updateSort,
    sort,
  }
}
