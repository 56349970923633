<template>
  <Modal @hide="reviewCreate.reset()">
    <template #activator="{ show }">
      <button
        class="bg-white dark:bg-[#275374] dark:border-white inline-flex items-center gap-1 py-1.5 border text-sm border-primary rounded-md px-2 font-normal hover:brightness-110"
        @click.stop="show"
      >
        <MagnifyingGlassPlusIcon class="w-4 h-4" />
        <span>Add review</span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[980px] create-review-modal flex flex-col">
        <div
          class="flex p-6 justify-between items-center border-b border-slate-300"
        >
          <p
            class="text-slate-700 text-lg font-semibold text-center flex gap-1 items-center"
          >
            <Cog8ToothIcon class="h-6" />
            Review wizard
          </p>
          <button @click="hide">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="flex-1 overflow-auto m-6 pr-2">
          <KeepAlive>
            <component
              :is="currentStepComponent"
              @create-review="createReview(hide)"
            />
          </KeepAlive>
        </div>
      </div>
      <div class="flex bg-white justify-between gap-6 p-6">
        <button
          class="w-full bg-white text-primary border border-primary rounded-lg text-base font-medium py-3"
          :disabled="
            reviewCreate.currentStep.value === CreateReviewStep.PresetSelection
          "
          :class="{
            'opacity-50 cursor-not-allowed':
              reviewCreate.currentStep.value ===
              CreateReviewStep.PresetSelection,
            'hover:brightness-125':
              reviewCreate.currentStep.value !==
              CreateReviewStep.PresetSelection,
          }"
          @click="reviewCreate.goToPreviousStep"
        >
          Previous
        </button>
        <button
          v-if="reviewCreate.currentStep.value !== CreateReviewStep.Summary"
          class="w-full bg-primary text-white rounded-lg text-base py-3 font-medium"
          :disabled="!reviewCreate.isCurrentStepValid.value"
          :class="{
            'opacity-50 cursor-not-allowed':
              !reviewCreate.isCurrentStepValid.value,
            'hover:brightness-125': reviewCreate.isCurrentStepValid.value,
          }"
          @click="reviewCreate.goToNextStep"
        >
          Next
        </button>
        <button
          v-else
          class="w-full bg-primary text-white rounded-lg text-base py-3 font-medium"
          :disabled="!reviewCreate.isCurrentStepValid.value"
          :class="{
            'opacity-50 cursor-not-allowed':
              !reviewCreate.isCurrentStepValid.value,
            'hover:brightness-125': reviewCreate.isCurrentStepValid.value,
          }"
          @click="createReview(hide)"
        >
          Confirm
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import { provide, computed } from 'vue'
import MagnifyingGlassPlusIcon from '@app/components/Icons/MagnifyingGlassPlusIcon.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { HttpException } from '@core/exceptions/http.exception'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import useCreateProjectReview, {
  CreateReviewKey,
  CreateReviewStep,
} from './useCreateProjectReview'
import useProjects from '../../use-projects'
import PresetSelection from './ReviewPreset/PresetSelection.vue'
import DeviceSpecificSearchLevel from './ReviewPreset/Presets/DeviceSpecificSearchPreset/DeviceSpecificSearchLevel.vue'
import Summary from './Summary/Summary.vue'
import GenericDeviceGroupPreset from './ReviewPreset/Presets/GenericDeviceGroupPreset.vue'
import MedicalAlternativePreset from './ReviewPreset/Presets/MedicalAlternativePreset.vue'
import MedicalBackgroundPreset from './ReviewPreset/Presets/MedicalBackgroundPreset.vue'
import SimilarDeviceSpecificSearchLevel from './ReviewPreset/Presets/SimilarDeviceSpecificSearchPreset/SimilarDeviceSpecificSearchLevel.vue'
import SimilarDeviceSpecificSearchMinimalPreset from './ReviewPreset/Presets/SimilarDeviceSpecificSearchPreset/SimilarDeviceSpecificSearchMinimalPreset.vue'
import DeviceSpecificSearchMinimalPreset from './ReviewPreset/Presets/DeviceSpecificSearchPreset/DeviceSpecificSearchMinimalPreset.vue'
import SimilarDeviceSpecificSearchFsnCountrySelection from './ReviewPreset/Presets/SimilarDeviceSpecificSearchPreset/SimilarDeviceSpecificSearchFsnCountrySelection.vue'
import { Cog8ToothIcon } from '@heroicons/vue/24/solid'
const props = defineProps<{
  projectId: Id
}>()
const stepsComponents = {
  [CreateReviewStep.PresetSelection]: PresetSelection,
  [CreateReviewStep.PmsDeviceLevelSelection]: DeviceSpecificSearchLevel,
  [CreateReviewStep.PmsDeviceLevelMinimal]: DeviceSpecificSearchMinimalPreset,

  [CreateReviewStep.PmsSimilarDeviceLevelSelection]:
    SimilarDeviceSpecificSearchLevel,
  [CreateReviewStep.PmsSimilarDeviceLevelMinimal]:
    SimilarDeviceSpecificSearchMinimalPreset,
  [CreateReviewStep.PmsSimilarDeviceFsnCountrySelection]:
    SimilarDeviceSpecificSearchFsnCountrySelection,
  [CreateReviewStep.genericDeviceGroupConfiguration]: GenericDeviceGroupPreset,
  [CreateReviewStep.MedicalAlternativeConfiguration]: MedicalAlternativePreset,
  [CreateReviewStep.MedicalBackgroundConfiguration]: MedicalBackgroundPreset,
  [CreateReviewStep.Summary]: Summary,
}
const reviewCreate = useCreateProjectReview(props.projectId)

const currentStepComponent = computed(() => {
  return stepsComponents[reviewCreate.currentStep.value]
})
const projects = useProjects()
provide(CreateReviewKey, reviewCreate)
const loading = useLoading()
const snackbar = useSnackbar()

async function createReview(cb: () => void) {
  if (
    reviewCreate.currentStep.value !== CreateReviewStep.Summary ||
    !reviewCreate.isCurrentStepValid.value
  )
    return

  loading.start()
  try {
    await reviewCreate.createReview()
    reviewCreate.reset()
    projects.refresh()
    hideAll()
    cb()
    snackbar.show(SnackbarState.SUCCESS, 'Review created successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  }
}
</script>
<style>
.create-review-modal {
  height: min(800px, calc(100vh - 300px));
}
</style>
