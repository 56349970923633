import { ReviewItem } from '@core/domain/models/reviewItem.model'

export function generateRisContent(studies: ReviewItem[]): string {
  return studies
    .map((study) => {
      let risContent = `TY  - JOUR\n`

      if (study.metadata?.title) {
        risContent += `TI  - ${study.metadata?.title}\n`
      }
      if (study.metadata?.abstract) {
        risContent += `AB  - ${study.metadata?.abstract}\n`
      }
      study.metadata?.authors.forEach((author: string) => {
        risContent += `AU  - ${author}\n`
      })
      if (study.metadata?.doi) {
        risContent += `DO  - ${study.metadata?.doi}\n`
      }
      if (study.metadata?.url) {
        risContent += `UR  - ${study.metadata?.url}\n`
      }
      if (study.metadata?.publishYear) {
        risContent += `PY  - ${study.metadata?.publishYear}\n`
      }
      if (study.metadata?.journal) {
        risContent += `JO  - ${study.metadata?.journal}\n`
      }
      if (study.metadata?.volumeNumber) {
        risContent += `VL  - ${study.metadata?.volumeNumber}\n`
      }
      if (study.metadata?.issueNumber) {
        risContent += `IS  - ${study.metadata?.issueNumber}\n`
      }
      if (study.metadata?.pagesNumber) {
        risContent += `SP  - ${study.metadata?.pagesNumber}\n`
      }

      risContent += `ER  -\n\n`
      return risContent
    })
    .join('')
}
