import { SettingsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { computed, ref } from 'vue'

const apiKey = ref()
export default () => {
  const settingsService = injectStrict(SettingsServiceKey)
  async function init() {
    apiKey.value = await settingsService.findOpenAiKey()
  }

  async function configure(apiKey: string) {
    await settingsService.configureAiAssistant(apiKey)
    await init()
  }

  async function getAssistant() {
    return settingsService.getAssistant()
  }

  async function updateAssistantModel(model: string) {
    await settingsService.updateAssistantModel(model)
  }

  const isConfigured = computed(() => !!apiKey.value)
  return {
    apiKey,
    configure,
    init,
    isConfigured,
    getAssistant,
    updateAssistantModel,
  }
}
