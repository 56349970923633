export function updateEndDateInPubmedQuery(queryString: string): string {
  const regex = /(\d{4}\/\d{2}\/\d{2})( \d{2}:\d{2})?"?\[Date - Publication\]/
  const match = regex.exec(queryString)

  if (match) {
    const endDate = match[1]
    const newTimestamp = Math.min(Date.now(), Date.parse(endDate))
    const newDateString = new Date(newTimestamp).toISOString().slice(0, 10)
    return queryString.replace(endDate, newDateString)
  }

  return queryString
}
