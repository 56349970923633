import { User } from '@core/domain/models/user.model'
import { Role } from '@core/domain/types/role.type'
import { UsersRepository } from '@infrastructure/datasource/users.repository'
import { Configuration, FrontendApi } from '@ory/client'

export class UsersService {
  constructor(private _usersRepository: UsersRepository) {}
  ory: FrontendApi = new FrontendApi(
    new Configuration({
      basePath: '/auth',
      baseOptions: {
        withCredentials: true,
      },
    }),
  )

  async create(data: {
    email: string
    role: Role
    password: string
    firstName: string
    lastName: string
  }): Promise<User> {
    return this._usersRepository.create(data)
  }

  async find(): Promise<User[]> {
    return this._usersRepository.find()
  }

  async login(email: string, password: string): Promise<string> {
    try {
      const { data } = await this.ory.createBrowserLoginFlow()
      const flow = data
      const nodeAttributes = flow.ui.nodes.find(
        (node) =>
          node.attributes.node_type === 'input' &&
          node.attributes.name === 'csrf_token',
      )?.attributes
      if (nodeAttributes?.node_type !== 'input')
        throw new Error('csrf_token not found')
      const csrfToken = nodeAttributes.value
      const login = await this.ory.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: {
          identifier: email,
          password: password,
          method: 'password',
          csrf_token: csrfToken,
        },
      })
      return login.data.session_token ?? ''
    } catch (e: unknown) {
      const error = e as any
      if (
        error?.response?.data?.error?.code === 400 &&
        error?.response?.data?.error.id === 'session_already_available'
      ) {
        return ''
      }
      throw e
    }
  }

  async findCurrentUser(): Promise<User> {
    const session = await this.ory.toSession()
    const user = new User({
      email: session.data.identity?.traits.email ?? '',
      firstName: session.data.identity?.traits.name?.first ?? '',
      lastName: session.data.identity?.traits.name?.last ?? '',
      id: session.data.identity?.id ?? '',
      role:
        (session.data.identity?.metadata_public as { role?: Role })?.['role'] ??
        Role.USER,
      isDisabled: !session.data.active,
      acceptedTermsVersion:
        (
          session.data.identity?.metadata_public as {
            acceptedTermsVersion?: string
          }
        )?.['acceptedTermsVersion'] ?? '',
    })
    return user
  }

  async logout(): Promise<void> {
    const { data } = await this.ory.createBrowserLogoutFlow()
    await this.ory.updateLogoutFlow({
      token: data.logout_token,
    })
  }

  async updateCurrentUserFullName(fullName: {
    firstName: string
    lastName: string
  }): Promise<void> {
    return this._usersRepository.updateCurrentUserFullName(fullName)
  }

  async enableUser(userId: string): Promise<void> {
    return this._usersRepository.enableUser(userId)
  }

  async edit(data: {
    userId: string
    firstName: string
    lastName: string
    role: Role
  }): Promise<void> {
    return this._usersRepository.edit(data)
  }

  async disableUser(userId: string): Promise<void> {
    return this._usersRepository.disableUser(userId)
  }

  async getRecoveryLink(): Promise<string> {
    return this._usersRepository.getRecoveryLink()
  }

  async requestRecoveryCode(email: string) {
    const { data: flow } = await this.ory.createBrowserRecoveryFlow()
    const nodeAttributes = flow.ui.nodes.find(
      (node) =>
        node.attributes.node_type === 'input' &&
        node.attributes.name === 'csrf_token',
    )?.attributes
    if (nodeAttributes?.node_type !== 'input')
      throw new Error('csrf_token not found')
    const csrfToken = nodeAttributes.value
    await this.ory.updateRecoveryFlow({
      flow: flow.id,
      updateRecoveryFlowBody: {
        email,
        csrf_token: csrfToken,
        method: 'code',
      },
    })
    return [flow.id, csrfToken]
  }

  async loginWithCode(code: string, flowId: string, csrfToken: string) {
    try {
      const { data } = await this.ory.updateRecoveryFlow({
        flow: flowId,
        updateRecoveryFlowBody: {
          code,
          csrf_token: csrfToken,
          method: 'code',
        },
      })
      const error = data.ui.messages?.find((m) => m.type === 'error')?.text
      if (error) throw new Error(error)
    } catch (e) {
      const error = e as { response: { data: { error: { id: string } } } }
      if (
        error?.response?.data?.error?.id === 'browser_location_change_required'
      )
        return
      throw e
    }
  }

  async changePassword(password: string) {
    const { data: flow } = await this.ory.createBrowserSettingsFlow()
    const nodeAttributes = flow.ui.nodes.find(
      (node) =>
        node.attributes.node_type === 'input' &&
        node.attributes.name === 'csrf_token',
    )?.attributes
    if (nodeAttributes?.node_type !== 'input')
      throw new Error('csrf_token not found')
    const csrfToken = nodeAttributes.value
    await this.ory.updateSettingsFlow({
      flow: flow.id,
      updateSettingsFlowBody: {
        csrf_token: csrfToken,
        password,
        method: 'password',
      },
    })
  }

  async acceptTermsOfService(version: string) {
    return this._usersRepository.acceptTermsOfService(version)
  }
}
