<template>
  <div class="container p-4 space-y-8">
    <TextInput
      v-model="query"
      placeholder="Type your query here"
      @keydown.enter="translateQuery"
    />
    <div v-if="translation">
      <ul>
        <li v-for="(value, key) in translation" :key="key">
          <strong>{{ key }}</strong
          >: {{ value }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
// @ts-expect-error - no types available
import polyglot from '@app/utils/polyglot'
import { ref } from 'vue'

type QueryTranslations = {
  'Business Source Ultimate': string
  'CINAHL (Ebsco)': string
  'Cochrane Library': string
  'Embase (Elsevier)': string
  'International HTA Database': string
  'Ovid MEDLINE': string
  'Ovid MEDLINE 2': string
  'ProQuest Health and Medical': string
  'PsycInfo (Ebsco)': string
  'PsycInfo (Ovid)': string
  'PubMed abbreviation': string
  'PubMed full': string
  SPORTDiscus: string
  'Scopus (advanced search)': string
  'Scopus (basic search)': string
  'Web of Science': string
  'WoS Advanced': string
  lexicalTreeJSON: Array<Record<string, any>>
}

const query = ref<string>('')
const translation = ref<QueryTranslations>()

function translateQuery() {
  translation.value = polyglot.translateAll(query.value)
}
</script>
