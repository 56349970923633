<template>
  <div class="px-4 py-4 space-y-6 container">
    <div>
      <table
        aria-describedby="evaluators table"
        class="w-full border-collapse table-fixed"
      >
        <thead>
          <tr>
            <th class="border-b border-black/20 px-4 py-2 text-left w-64">
              Role
            </th>
            <th class="border-b border-black/20 px-4 py-2 text-left">
              Members
            </th>
            <th class="border-b border-black/20 px-4 py-2 text-left w-16"></th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="evaluatorGroup in review.entity.value.evaluatorGroups"
            :key="evaluatorGroup.role"
          >
            <EvaluatorGroupComponent
              :evaluator-group="evaluatorGroup"
              @add-member-to-group="addMemberToGroup"
              @remove-member-from-group="removeMemberFromGroup"
              @remove-group="removeGroup"
              @update-role-name="updateGroupRoleName"
            />
          </template>

          <tr v-if="!review.isPlanReadonly.value">
            <td class="border-b border-black/20 px-4 py-2" colspan="3">
              <InlineTextInput
                ref="roleInput"
                v-model="newGroup.role"
                :disabled="review.isPlanReadonly.value"
                placeholder="Define a role"
                multiline
                @save="(v: string) => createEvaluatorGroup(v)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import EvaluatorGroupComponent from './EvaluatorGroup.vue'
import { EvaluatorGroup } from '@core/domain/models/evaluatorGroup.model'
import { ref } from 'vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'

const review = injectStrict(ReviewKey)
const loading = useLoading()
const snackbar = useSnackbar()
const newGroup = ref({ role: '', members: [], id: 0 })

async function createEvaluatorGroup(role: string) {
  if (!role) return
  loading.start()
  try {
    await review.createEvaluatorGroup(role)
    snackbar.success('Role created')
  } catch (_e) {
    snackbar.error('Failed to create role')
  } finally {
    loading.stop()
  }
  newGroup.value = { role: '', members: [], id: 0 }
}

async function addMemberToGroup(memberName: string, group: EvaluatorGroup) {
  loading.start()
  try {
    await review.addMemberToGroup(memberName, group)
    snackbar.success('Member added to role')
  } catch (_e) {
    snackbar.error('Failed to add member to role')
  } finally {
    loading.stop()
  }
}

async function removeMemberFromGroup(
  memberName: string,
  group: EvaluatorGroup,
) {
  loading.start()
  try {
    await review.removeMemberFromGroup(memberName, group)
    snackbar.success('Member removed from role')
  } catch (_e) {
    snackbar.error('Failed to remove member from role')
  } finally {
    loading.stop()
  }
}

async function updateGroupRoleName(group: EvaluatorGroup, role: string) {
  loading.start()
  try {
    await review.updateGroupRoleName(group, role)
    snackbar.success('Role updated')
  } catch (_e) {
    snackbar.error('Failed to update role')
  } finally {
    loading.stop()
  }
}

async function removeGroup(group: EvaluatorGroup) {
  loading.start()
  try {
    await review.removeGroup(group)
    snackbar.success(group.role + ' removed')
  } catch (_e) {
    snackbar.error('Failed to remove ' + group.role)
  } finally {
    loading.stop()
  }
}
</script>
