export function deepEqual<T>(object1: T, object2: T): boolean {
  if (object1 === object2) {
    return true
  }
  if (!isObject(object1) || !isObject(object2)) {
    return false
  }

  const keys1 = Object.keys(object1) as (keyof T)[]
  const keys2 = Object.keys(object2) as (keyof T)[]

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(object1[key], object2[key])) {
      return false
    }
  }

  return true
}

function isObject(obj: unknown): obj is Record<string, unknown> {
  return typeof obj === 'object' && obj !== null
}
