<template>
  <div>
    <div class="overflow-hidden rounded-lg shadow-sm">
      <table
        aria-describedby="roles breakdown table"
        class="w-full border-collapse border-0 bg-white"
      >
        <thead>
          <tr>
            <th class="w-1/2 px-6 py-3 bg-primary rounded-tl-lg"></th>
            <th
              v-for="(role, index) in roles"
              :key="role"
              class="px-6 py-3 bg-primary text-white text-center"
              :class="{
                'rounded-tr-lg': index === roles.length - 1,
              }"
            >
              {{ role }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(permission, index) in permissions"
            :key="permission.name"
            :class="{ 'bg-slate-100': index % 2 === 0 }"
          >
            <td class="px-6 py-2 text-slate-600">{{ permission.name }}</td>
            <td v-for="role in roles" :key="role" class="px-6 py-4 text-center">
              <span
                v-if="permission.roles.includes(role)"
                class="text-green-600"
              >
                <CheckCircleIcon class="w-5 h-5 mx-auto" />
              </span>
              <span v-else class="text-slate-300">
                <XCircleIcon class="w-5 h-5 mx-auto" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'

const roles = ref<string[]>(['Admin', 'User', 'Reader'])
const permissions = ref<
  {
    name: string
    roles: string[]
  }[]
>([
  {
    name: 'Manage users',
    roles: ['Admin'],
  },
  {
    name: 'Create project',
    roles: ['Admin'],
  },
  {
    name: 'Manage all projects',
    roles: ['Admin'],
  },
  {
    name: 'Delete project',
    roles: ['Admin'],
  },
  {
    name: 'Manage own projects',
    roles: ['Admin', 'User'],
  },
  {
    name: 'View own projects',
    roles: ['Admin', 'User', 'Reader'],
  },
  {
    name: 'Assign user to project',
    roles: ['Admin'],
  },
  {
    name: 'Create review',
    roles: ['Admin'],
  },
  {
    name: 'Delete review',
    roles: ['Admin'],
  },
  {
    name: 'Lock/unlock plan',
    roles: ['Admin'],
  },
  {
    name: 'Lock/unlock screening',
    roles: ['Admin'],
  },
  {
    name: 'Archive review',
    roles: ['Admin'],
  },
  {
    name: 'Edit review plan',
    roles: ['Admin', 'User'],
  },
  {
    name: 'Execute review',
    roles: ['Admin', 'User'],
  },
  {
    name: 'Export files',
    roles: ['Admin', 'User', 'Reader'],
  },
])
</script>
