import { InjectionKey } from 'vue'

export const EventDispatcherKey: InjectionKey<EventDispatcher> =
  Symbol('EventDispatcher')

export class EventDispatcher {
  private readonly handlers = new Map<string, Set<EventHandler<any>>>()

  registerHandler<T>(
    eventType: new (...args: any[]) => T,
    handler: EventHandler<T>,
  ): void {
    const handlers = this.handlers.get(eventType.name) ?? new Set()
    handlers.add(handler)
    this.handlers.set(eventType.name, handlers)
  }

  dispatch<T extends object>(event: T): void {
    const handlers = this.handlers.get(event.constructor.name)
    if (handlers) {
      handlers.forEach((handler) => handler(event))
    }
  }
}

export type EventHandler<T> = (event: T) => void
