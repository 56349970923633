<template>
  <div v-if="!isChromium">
    <div class="rounded-md bg-yellow-50 p-4">
      <div class="flex items-center">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3">
          <div class="text-sm text-yellow-700">
            <p>Please use google chrome for the app to work properly.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <component :is="componentName">
    <RouterView :key="(route.params?.id as string) ?? route.name ?? ''" />
  </component>
  <CookiesNotice />
  <Snackbar />
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router'
import AppLayout from './layouts/AppLayout.vue'
import BlankLayout from './layouts/BlankLayout.vue'
import Snackbar from './views/Snackbar.vue'
import CookiesNotice from './views/CookiesNotice.vue'
import { computed, onMounted, ref } from 'vue'

import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'

const isDarkMode = ref(false)
const route = useRoute()
const isChromium = !!window.chrome

const componentName = computed(() => {
  return route.path === '/login' ||
    route.path === '/forgot-password' ||
    route.path === '/terms-of-service'
    ? BlankLayout
    : AppLayout
})

onMounted(() => {
  const activeTheme = localStorage.getItem('theme')
  if (!activeTheme || activeTheme === 'light') {
    document.body.classList.remove('dark')
    isDarkMode.value = false
  } else {
    document.body.classList.add('dark')
    isDarkMode.value = true
  }
})
</script>
