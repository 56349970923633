import { ImportSource } from '@core/domain/models/import-source.model'
import { ref } from 'vue'

const builtInImportSources = ref<ImportSource[]>([])
export default () => {
  async function set(sources: ImportSource[]) {
    builtInImportSources.value = sources
  }
  function findById(sourceId: string) {
    return builtInImportSources.value.find(
      (importSource) => importSource.id === sourceId,
    )
  }
  return { set, findById }
}
