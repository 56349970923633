export class GuidanceMetadata {
  title?: string
  attachmentLink?: string
  reference?: string
  publishYear?: string
  section?: string

  constructor(data: {
    title?: string
    attachmentLink?: string
    reference?: string
    publishYear?: string
    section?: string
  }) {
    this.title = data.title
    this.attachmentLink = data.attachmentLink
    this.reference = data.reference
    this.publishYear = data.publishYear
    this.section = data.section
  }
}
