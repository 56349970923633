type responseData = {
  error: string
  message: string
  statusCode: number
}
export function errorMessage(response: responseData): string {
  if (response.statusCode >= 400 && response.statusCode < 500) {
    return response.message
  } else {
    return 'an error occurred'
  }
}
