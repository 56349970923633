export class ArticleMetadata {
  title?: string
  abstract?: string
  authors?: string[]
  doi?: string
  url?: string
  journal?: string
  volumeNumber?: string
  issueNumber?: string
  pagesNumber?: string
  rawData?: string
  publishYear?: string
  pmid?: string
  potentialPdfUrl?: string
  constructor(data: {
    title?: string
    abstract?: string
    authors?: string[]
    doi?: string
    url?: string
    journal?: string
    volumeNumber?: string
    issueNumber?: string
    pagesNumber?: string
    rawData?: string
    publishYear?: string
    pmid?: string
    potentialPdfUrl?: string
  }) {
    this.title = data.title
    this.abstract = data.abstract
    this.authors = data.authors ?? []
    this.doi = data.doi
    this.url = data.url
    this.publishYear = data.publishYear
    this.rawData = data.rawData ?? ''
    this.journal = data.journal
    this.pagesNumber = data.pagesNumber ?? ''
    this.issueNumber = data.issueNumber ?? ''
    this.volumeNumber = data.volumeNumber ?? ''
    this.pmid = data.pmid
    this.potentialPdfUrl = data.potentialPdfUrl
  }
}
