<template>
  <div class="space-y-4">
    <h3 class="font-medium flex items-center gap-2">Select a type of review</h3>
    <div
      v-if="currentStep === CreateReviewStep.PresetSelection"
      class="flex-shrink-0 container"
    >
      <div class="grid grid-cols-2 gap-2 flex-wrap">
        <CallToAction
          v-for="(p, k) in reviewPresetsMap"
          :key="k"
          :is-active="k === preset.preset"
          :title="p.title"
          :subtitle="p.subtitle"
          :description="p.description"
          @handle="setPreset(k)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { injectStrict } from '@app/utils/injectStrict'
import {
  CreateReviewKey,
  CreateReviewStep,
  reviewPresetsMap,
} from '../useCreateProjectReview'
import { nextTick } from 'vue'
import CallToAction from './components/CallToAction.vue'

const { preset, currentStep, goToNextStep } = injectStrict(CreateReviewKey)

function setPreset(newPreset: ReviewPreset) {
  preset.value.preset = newPreset
  nextTick(() => goToNextStep())
}
</script>
