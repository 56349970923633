<template>
  <div
    class="border border-[#D9E4EA] py-3 dark:border-[#345C7C] px-2 items-center shrink-0 max-w-[399px]"
    :class="{
      'bg-azureish-white/25': isHoveredOver,
    }"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
  >
    <div class="flex gap-2">
      <StudyDuplicationButton
        :study="study"
        :is-loading="isLoading"
        @mark-as-duplicate="markStudyAsDuplicate"
        @mark-as-not-duplicate="markStudyAsNotDuplicate"
      />
      <StudyTitleAndAbstractScreeningButton
        v-if="
          review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
            .length ?? 0 > 0
        "
        :study="study"
        :is-loading="isLoading"
        @clear-title-and-abstract-screening="clearTitleAndAbstractScreening"
        @set-title-and-abstract-screening="setTitleAndAbstractScreening"
      />
      <StudyFullTextScreeningButton
        :study="study"
        :is-loading="isLoading"
        @clear-full-text-screening="clearFullTextScreening"
        @set-full-text-screening="setFullTextScreening"
      />
    </div>
    <div v-if="expansionPanel.isShown.value" class="mb-4 mt-8">
      <div class="w-full">
        <span
          v-if="study.state === StudyState.INCLUDED"
          class="bg-primary-green px-2 py-1 text-black rounded-md text-sm"
        >
          Included
        </span>
        <div
          v-else-if="study.isDuplicate"
          class="w- truncate bg-red-500 px-2 py-1 rounded-md text-white text-xs"
        >
          Duplicate of (ID:
          {{ duplicationParentStudy?.id }})
        </div>

        <span
          v-else-if="exclusionCriterion"
          class="bg-red-500 px-2 py-1 rounded-md text-white text-xs"
        >
          {{ exclusionCriterion }}
        </span>
        <span
          v-else
          class="bg-neutral-900 px-2 py-1 rounded-md text-white text-xs"
        >
          Unscreened
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState, StudyState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { HttpException } from '@core/exceptions/http.exception'
import { hideAll } from 'tippy.js'

import { computed, ref } from 'vue'
import StudyDuplicationButton from './StudyDuplicationButton.vue'
import StudyTitleAndAbstractScreeningButton from './StudyTitleAndAbstractScreeningButton.vue'
import StudyFullTextScreeningButton from './StudyFullTextScreeningButton.vue'
const props = defineProps<{
  study: ReviewItem
  screeningStage?: boolean

  expansionPanel: any
}>()

const loading = useLoading()
const isLoading = ref(false)

const review = injectStrict(ReviewKey)

const snackbar = useSnackbar()
const isHoveredOver = defineModel<boolean>()

function onMouseOver() {
  isHoveredOver.value = true
}
function onMouseOut() {
  isHoveredOver.value = false
}

const duplicationParentStudy = computed(() => {
  if (props.study.state !== StudyState.DUPLICATE) return null
  return review.entity.value.studies.find(
    (a) => a.id === props.study.parentStudyId,
  )
})

async function clearFullTextScreening() {
  try {
    startLoading()
    await review.clearStudyFullTextScreening(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully cleared full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

function startLoading() {
  loading.start()
  isLoading.value = true
}
function stopLoading() {
  loading.stop()
  isLoading.value = false
}

const exclusionCriterion = computed(() => {
  if (
    props.study.titleAndAbstractScreening !== '' &&
    props.study.titleAndAbstractScreening !== 'Included'
  )
    return props.study.titleAndAbstractScreening
  if (
    props.study.fullTextScreening !== '' &&
    props.study.fullTextScreening !== 'Included'
  )
    return props.study.fullTextScreening
  return ''
})

async function markStudyAsDuplicate(parentStudyId: number) {
  try {
    startLoading()
    await review.markStudyAsDuplicate(props.study.id, parentStudyId)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study marked as duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    stopLoading()
  }

  stopLoading()
}

async function markStudyAsNotDuplicate() {
  try {
    startLoading()
    await review.markStudyAsNotDuplicate(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study marked as not duplicate successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setTitleAndAbstractScreening(key: string) {
  if (
    !props.study.pdfFile &&
    review.entity.value?.plan?.screeningPlan?.titleAndAbstractCriteria
      ?.length === 0 &&
    key === 'Included'
  ) {
    snackbar.show(
      SnackbarState.ERROR,
      'You must upload the study to continue. Please upload a clean document without highlights or comments',
    )
    return
  }
  try {
    startLoading()
    await review.setStudyTitleAndAbstractScreening(props.study.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully updated title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function clearTitleAndAbstractScreening() {
  try {
    startLoading()
    await review.clearStudyTitleAndAbstractScreening(props.study.id)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully cleared title and abstract screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}

async function setFullTextScreening(key: string) {
  try {
    startLoading()
    await review.setStudyFullTextScreening(props.study.id, key)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Successfully updated full text screening',
    )
    hideAll({ duration: 0 })
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    stopLoading()
  }
}
</script>
