import { onMounted, ref } from 'vue'

const isExtensionInstalled = ref(true)
const isExtensionAuthenticated = ref(true)
const extensionId = 'bifaoaidegbcmjliabaeabnniphbaodi'
export default () => {
  onMounted(() => {
    setTimeout(async () => {
      const extensionInstalledElement = document.getElementById(
        'evidence-extension-installed',
      )
      if (extensionInstalledElement) {
        isExtensionInstalled.value = true
      } else {
        isExtensionInstalled.value = false
      }
    }, 1000)
  })

  async function checkExtensionAuthenticated() {
    isExtensionAuthenticated.value = await sendMessageToExtension<boolean>({
      type: 'isAuthenticated',
    }).catch(() => false)
  }

  async function loginExtension(recoveryLink: string) {
    const loggedIn = await sendMessageToExtension({
      type: 'login',
      recoveryLink,
      instanceUrl: window.location.origin,
    })
    if (loggedIn === 'success') {
      isExtensionAuthenticated.value = true
    }
  }

  async function logoutExtension() {
    const loggedOut = await sendMessageToExtension({
      type: 'logout',
    })
    if (loggedOut === 'success') {
      isExtensionAuthenticated.value = false
    }
  }
  return {
    isExtensionInstalled,
    isExtensionAuthenticated,
    loginExtension,
    checkExtensionAuthenticated,
    logoutExtension,
  }
}

function sendMessageToExtension<T>(payload: unknown): Promise<T> {
  return new Promise((resolve, reject) => {
    if (!chrome.runtime) {
      reject(new Error('Chrome runtime not available'))
    }
    chrome.runtime.sendMessage(extensionId, payload, function (response) {
      resolve(response)
    })
  })
}
