<template>
  <div
    :id="importSource.id"
    class="p-6 space-y-6 hover:bg-slate-200 rounded-xl"
    :data-navigation="importSource.name"
  >
    <div class="flex justify-between items-start">
      <div class="w-full flex justify-between">
        <div class="flex items-center gap-2 w-1/3">
          <template v-if="sourceIconUrl">
            <img
              v-show="isLogoValid"
              :key="sourceIconUrl"
              class="w-8 h-8"
              :src="sourceIconUrl"
              alt="source logo"
              @load="
                ({ target: imageElement }) =>
                  checkImage(imageElement as HTMLImageElement)
              "
              @error="
                ({ target: imageElement }) =>
                  handleImageError(imageElement as HTMLImageElement)
              "
            />
            <div
              v-show="!isLogoValid"
              class="w-8 h-8 p-1 border-primary/50 border-2 text-primary/80 rounded-full"
            >
              <DatabaseIcon class="w-full h-full" />
            </div>
          </template>
          <div
            v-else
            class="w-8 h-8 p-1 border-primary/50 border-2 text-primary/80 rounded-full"
          >
            <DatabaseIcon class="w-full h-full" />
          </div>
          <div
            class="flex items-center gap-2 text-blue-800 font-inter text-base font-semibold leading-7"
          >
            <a
              v-if="
                ![
                  BuiltInImportSourceId.HAND_SEARCH,
                  BuiltInImportSourceId.CITATION_SEARCH,
                  BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
                ].includes(importSource.id as BuiltInImportSourceId)
              "
              :href="importSource.url"
              class="underline underline-offset-4 truncate"
              target="_blank"
            >
              {{ importSource.name }}
            </a>
            <div v-else>
              {{ importSource.name }}
            </div>
            <button
              v-if="
                (review.searchesBySource.value[importSource.id]?.length ?? 0) <=
                  0 && !review.isPlanReadonly.value
              "
              @click="removeImportSourceFromPlan(importSource.id)"
            >
              <TrashIcon class="w-4 h-4 text-red-600" />
            </button>
          </div>
        </div>
        <template
          v-if="
            ![
              BuiltInImportSourceId.HAND_SEARCH,
              BuiltInImportSourceId.CITATION_SEARCH,
              BuiltInImportSourceId.FIELD_SAFETY_NOTICES,
            ].includes(importSource.id as BuiltInImportSourceId) &&
            isBuiltInImportSource(importSource.id)
          "
        >
          <a
            v-if="
              isExtensionInstalled &&
              review.entity.value.plan?.preset ===
                ReviewPreset.MEDICAL_BACKGROUND &&
              sourcesWithSearchUrl.includes(importSource.id)
            "
            v-tooltip="getTooltipText()"
            :href="getImportSourceUrl()"
            target="_blank"
            rel="noopener"
            class="rounded-md w-2/3 text-right text-blue-500 underline underline-offset-4 px-4 py-2 flex items-center gap-2"
          >
            <span
              class="block flex-1 truncate text-sm"
              v-html="getImportSourceButtonText()"
            />

            <ArrowTopRightOnSquareIcon class="w-4" />
          </a>

          <a
            v-if="!isExtensionInstalled"
            href="https://chromewebstore.google.com/detail/evidence-extension/bifaoaidegbcmjliabaeabnniphbaodi"
            target="_blank"
            rel="noopener"
            class="rounded-md border-primary border-solid bg-white text-primary border px-2 py-2 flex items-center gap-2"
          >
            <ChromeLogoIcon />
            <span class="block flex-1 truncate"
              >Install the chrome extension to import from
              {{ importSource.name }}</span
            >
            <ArrowTopRightOnSquareIcon class="w-4" />
          </a>
        </template>
      </div>
    </div>
    <div
      v-if="
        isSelected && review.searchesBySource.value[importSource.id]?.length > 0
      "
    >
      <table
        aria-describedby="searches tables"
        class="w-full border-collapse border-spacing-7"
      >
        <thead>
          <tr>
            <th class="text-left align-top w-20">
              <div class="p-1 text-primary">Search</div>
            </th>
            <th class="text-left align-top w-28">
              <div class="p-1 text-primary">Date</div>
            </th>
            <th
              v-if="importSource.id === BuiltInImportSourceId.CITATION_SEARCH"
              class="text-left align-top w-12"
            >
              <div class="p-1 text-primary">Parent</div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH &&
                importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
              "
              class="text-left align-top w-20"
            >
              <div class="p-1 text-primary">Details</div>
            </th>
            <th
              v-if="
                importSource.id !== BuiltInImportSourceId.HAND_SEARCH &&
                importSource.id !== BuiltInImportSourceId.CITATION_SEARCH
              "
              class="text-left align-top"
            >
              <div class="p-1 text-primary">Query</div>
            </th>

            <th class="text-left align-top">
              <div
                v-if="
                  importSource.id === BuiltInImportSourceId.FIELD_SAFETY_NOTICES
                "
                class="p-1 max-w-[500px] text-primary"
              >
                Databases
              </div>
            </th>
            <th class="align-top text-left w-12">
              <div class="p-1 text-primary">Results</div>
            </th>
            <th class="text-left align-top w-16">
              <div class="p-1"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(search, index) in getSourceSearches(importSource.id)"
            :key="importSource.id + index"
          >
            <SearchRow
              :search="search"
              :search-number="index + 1"
              :import-source="importSource"
            />
          </template>
        </tbody>
      </table>
    </div>
    <div v-else class="bg-white/50 p-2">
      You haven’t imported any search yet.
    </div>

    <slot
      v-if="
        review.entity.value.plan?.lockState !== ReviewLockState.LOCKED &&
        importSource.id !== BuiltInImportSourceId.FIELD_SAFETY_NOTICES
      "
      name="bottom"
    />
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { Search } from '@core/domain/models/search.model'
import TrashIcon from '@app/components/Icons/TrashIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { BuiltInImportSourceId } from '@core/domain/types/builtInImportSourceId'
import { computed, ref } from 'vue'
import { ImportSource } from '@core/domain/models/import-source.model'
import DatabaseIcon from '@app/components/Icons/DatabaseIcon.vue'
import { watchDebounced } from '@vueuse/core'
import { isUrlValid } from '@app/utils/urlValidation'
import { ReviewLockState } from '@core/domain/types/reviewLockState.type'

import useWebExtension from '@app/composables/useWebExtension'

import SearchRow from './ImportSourceCard/SearchRow.vue'
import useBuiltInImportSources from '@app/composables/use-built-in-import-sources'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import ChromeLogoIcon from '@app/components/Icons/ChromeLogoIcon.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'

const { isExtensionInstalled } = useWebExtension()

const builtInImportSources = useBuiltInImportSources()

const props = defineProps<{
  importSource: ImportSource
  tooltip?: {
    text: string
    url?: string
  }
  iconUrl?: string
  query?: string
}>()

function buildSourceIconUrl(sourceUrl?: string): string {
  if (props.iconUrl) return props.iconUrl
  if (!sourceUrl || !isUrlValid(sourceUrl))
    return '/sources-icons/custom-source.png'

  return `https://www.google.com/s2/favicons?domain=${encodeURIComponent(
    sourceUrl,
  )}&sz=64`
}

const sourceIconUrl = ref(buildSourceIconUrl(props.importSource.url ?? ''))

watchDebounced(
  () => props.importSource.url,
  (url) => {
    sourceIconUrl.value = buildSourceIconUrl(url)
  },
  {
    debounce: 500,
    maxWait: 1000,
  },
)

const isLogoValid = ref(true)
const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)
const isSelected = computed(() =>
  review.entity?.value?.plan?.importPlan?.importSources?.some(
    (ri) => ri.id === props.importSource.id,
  ),
)

const emit = defineEmits<(e: 'importSourceRemoved') => void>()

async function removeImportSourceFromPlan(sourceId: string) {
  try {
    loading.start()
    emit('importSourceRemoved')
    await review.removeImportSourceFromPlan(sourceId)
    snackbar.show(SnackbarState.SUCCESS, 'Source removed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
  } finally {
    loading.stop()
  }
}

function checkImage(target: HTMLImageElement) {
  const img = target

  if (img.naturalWidth === 16 && img.naturalHeight === 16) {
    isLogoValid.value = false
  } else {
    isLogoValid.value = true
  }
}

function handleImageError(target: HTMLImageElement) {
  target.src = '/sources-icons/custom-source.png'
}

function getSourceSearches(importSourceId: string): Search[] {
  return review.searchesBySource.value[importSourceId] ?? []
}

const isBuiltInImportSource = (importSourceId: string) => {
  return !!builtInImportSources.findById(importSourceId)
}
const sourcesWithSearchUrl = [
  'pubmed',
  'cochrane-database-of-systematic-reviews',
  'open-alex',
]

function getImportSourceUrl() {
  if (props.importSource.id === 'pubmed')
    return `https://pubmed.ncbi.nlm.nih.gov/?term=${review.entity.value.plan?.problemName}[Title] AND (guideline*[Title])&filter=datesearch.y_10`
  else if (props.importSource.id === 'cochrane-database-of-systematic-reviews')
    return `https://www.cochranelibrary.com/search?p_p_id=scolarissearchresultsportlet_WAR_scolarissearchresults&p_p_lifecycle=0&_scolarissearchresultsportlet_WAR_scolarissearchresults_searchType=basic&_scolarissearchresultsportlet_WAR_scolarissearchresults_searchBy=1&_scolarissearchresultsportlet_WAR_scolarissearchresults_searchText=${review.entity.value.plan?.problem}&publishDateFrom=01%2F01%2F2014`
  else if (props.importSource.id === 'open-alex')
    return `https://openalex.org/works?filter=display_name.search%3a${review.entity.value.plan?.problemName} AND guideline,publication_year%3A2014-2024`
}

function generateQuery() {
  const { id } = props.importSource
  const { plan } = review.entity.value

  if (id === 'pubmed') {
    return `${plan?.problemName}[Title] AND (guideline*[Title])`
  } else if (id === 'cochrane-database-of-systematic-reviews') {
    return `Indication: ${plan?.problem}`
  } else if (id === 'open-alex') {
    return `Title: ${plan?.problemName} AND guideline`
  }
  return ''
}

function getImportSourceButtonText() {
  return `<strong class='font-semibold'>Run this search:</strong> ${generateQuery()}`
}

function getTooltipText() {
  return `Run this search: ${generateQuery()}`
}
</script>
