<template>
  <div class="relative">
    <slot
      :has-file-dragged-over="hasFileDraggedOver"
      :has-file-over-window="hasFileOverWindow"
      :trigger-file-picker="triggerFilePicker"
    ></slot>
    <div
      v-if="hasFileOverWindow"
      class="absolute inset-0"
      @drop="handleFileDrop"
      @dragover="highlightDropZone"
      @dragleave="removeDropZoneHighlight"
    >
      <slot name="filePresentInWindow" />
    </div>
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      :accept="acceptedFileTypes.join(', ')"
      :multiple="acceptMultipleFiles"
      @change="handleFileSelection"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'

const hasFileDraggedOver = ref(false)
const hasFileOverWindow = ref(false)
const emit = defineEmits<{
  (e: 'fileDropped', file: File): void
  (e: 'filesDropped', file: File[]): void
}>()

const props = withDefaults(
  defineProps<{
    acceptedFileTypes?: string[]
    acceptMultipleFiles?: boolean
  }>(),
  {
    acceptedFileTypes: () => [],
    acceptMultipleFiles: false,
  },
)

function handleFileDrop(e: DragEvent) {
  hasFileDraggedOver.value = false
  hasFileOverWindow.value = false
  const files = Array.from(e.dataTransfer?.files ?? [])
  processFiles(files)
}

function handleFileSelection(event: Event) {
  const input = event.target as HTMLInputElement
  if (!input.files) return
  const files = Array.from(input.files)
  input.value = ''
  processFiles(files)
}

const fileInput = ref<null | HTMLInputElement>(null)

function triggerFilePicker() {
  fileInput.value?.click()
}

function processFiles(selectedFiles: File[]) {
  if (!props.acceptMultipleFiles) {
    const file = selectedFiles[0]
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''

      if (
        props.acceptedFileTypes.includes(`.${fileExtension}`) ||
        props.acceptedFileTypes.includes(fileExtension)
      ) {
        emit('fileDropped', file)
      } else {
        console.log('Unsupported file')
      }
    }
  } else if (
    selectedFiles.every((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? ''
      return (
        props.acceptedFileTypes.includes(`.${fileExtension}`) ||
        props.acceptedFileTypes.includes(fileExtension)
      )
    })
  ) {
    emit('filesDropped', selectedFiles)
  } else {
    console.log('One or more files are unsupported')
  }
}

function highlightDropZone(e: DragEvent) {
  const types = e.dataTransfer?.types
  if (types?.includes('Files')) {
    hasFileDraggedOver.value = true
  }
}

function removeDropZoneHighlight() {
  hasFileDraggedOver.value = false
}

function preventDefaults(e: Event) {
  e.preventDefault()
}

function handleFileEnter() {
  hasFileOverWindow.value = true
}

function handleFileLeave(e: DragEvent) {
  if (
    e.relatedTarget === null ||
    e.relatedTarget === document.documentElement
  ) {
    hasFileOverWindow.value = false
  }
}

function resetFileOverWindow() {
  hasFileOverWindow.value = false
}

const globalEvents = ['dragover', 'dragenter', 'dragleave', 'drop']

onMounted(() => {
  globalEvents.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults, false)
  })
  window.addEventListener('dragenter', handleFileEnter, false)
  window.addEventListener('dragleave', handleFileLeave, false)
  window.addEventListener('drop', resetFileOverWindow, false)
})

onUnmounted(() => {
  globalEvents.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults, false)
  })
  window.removeEventListener('dragenter', handleFileEnter, false)
  window.removeEventListener('dragleave', handleFileLeave, false)
  window.removeEventListener('drop', resetFileOverWindow, false)
})
</script>
