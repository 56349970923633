<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-za"
    viewBox="0 0 512 512"
  >
    <defs>
      <clipPath id="za-a">
        <path fill-opacity=".7" d="M70.1 0h499.6v499.6H70.1z" />
      </clipPath>
    </defs>
    <g clip-path="url(#za-a)" transform="translate(-71.9)scale(1.0248)">
      <g fill-rule="evenodd" stroke-width="1pt">
        <path fill="#000001" d="M0 397.9v-296l220.4 147.9z" />
        <path fill="#000c8a" d="m150.4 499.7 247.4-166.5h351.6v166.5z" />
        <path fill="#e1392d" d="M134.5 0h615v166.6H397.7S137.8-1.6 134.5 0" />
        <path
          fill="#ffb915"
          d="M0 62.5v39.3l220.4 148L0 397.8v39.4l277.6-187.4z"
        />
        <path
          fill="#007847"
          d="M0 62.5V0h92.6l294 199h362.8v101.7H386.6l-294 198.9H0v-62.4l277.6-187.4z"
        />
        <path
          fill="#fff"
          d="M92.6 0h57.8l247.4 166.6h351.6V199H386.6zm0 499.7h57.8l247.4-166.5h351.6v-32.4H386.6z"
        />
      </g>
    </g>
  </svg>
</template>
