<template>
  <Modal v-if="isAdmin" dismissible>
    <template #activator="{ show }">
      <button
        class="ml-2 px-2 py-2"
        :class="[isShown ? 'text-white/80 hover:text-white' : '']"
        @click.stop="show"
      >
        <UsersIcon class="w-5 h-5" />
      </button>
    </template>
    <template #content>
      <div class="rounded-lg w-[900px] h-[600px]">
        <div class="h-full flex flex-col">
          <div class="h-full flex flex-col overflow-hidden">
            <div class="flex px-8 flex-col gap-6 w-full sticky top-0 z-50">
              <div class="bg-white z-50 space-y-6 py-4">
                <h2
                  class="text-lg pb-4 font-bold text-primary dark:text-white flex items-center gap-2"
                >
                  Assign members to project
                </h2>

                <div class="space-y-4">
                  <ProjectMembers
                    :project="project"
                    class="shrink-0 w-[400px]"
                  />

                  <div class="w-full">
                    <div class="w-full flex flex-wrap gap-4">
                      <template
                        v-for="user in project.users"
                        :key="'user-' + user.id"
                      >
                        <div
                          class="rounded-full dark:text-white text-black text-center max-w-fit border-2 py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap hover:cursor-pointer"
                          :style="{
                            'background-color': `${usersColors[user?.id ?? '']}40`,
                          }"
                        >
                          <div
                            class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
                            :style="{
                              backgroundColor: usersColors[user?.id ?? ''],
                            }"
                          >
                            <p class="h-3 w-3 font-medium leading-3">
                              {{ buildUserFullname(user)[0].toUpperCase() }}
                            </p>
                          </div>
                          <div class="leading-none">
                            {{ buildUserFullname(user) }}
                          </div>
                          <div class="w-5 h-5 flex items-center justify-center">
                            <button
                              v-tooltip="
                                !canDeleteMember()
                                  ? 'Cannot remove member. Assigned in some reviews.'
                                  : ''
                              "
                              :class="{
                                'opacity-50 cursor-not-allowed':
                                  !canDeleteMember(),
                              }"
                              class="hover:bg-transparent"
                              @click.stop="
                                removeProjectMember(project.id!, user?.id)
                              "
                            >
                              <XMarkIcon class="h-[16.5px]" />
                            </button>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> </template
  ></Modal>
</template>
<script setup lang="ts">
import { Project } from '@core/domain/models/project.model'
import { User } from '@core/domain/models/user.model'
import { computed } from 'vue'
import XMarkIcon from '@app/components/Icons/XMarkIcon.vue'
import ProjectMembers from './ProjectMembers/ProjectMembers.vue'
import useProjects from '@app/views/Projects/use-projects'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { UsersIcon } from '@heroicons/vue/24/solid'

const { user: authenticatedUser } = injectStrict(AuthKey)
const isAdmin = computed(() => authenticatedUser.value?.role === Role.ADMIN)

const props = defineProps<{
  project: Project
  isShown?: boolean
}>()

const { removeProjectMember } = useProjects()

const availableColors = [
  '#A1CAE9',
  '#AFB5F1',
  '#C8AFF1',
  '#F6BBFB',
  '#F8AFD2',
  '#F8AFAF',
  '#F8C9AF',
  '#F8DFAF',
  '#EBE28F',
  '#C2F8AF',
  '#AFF8C3',
  '#AFF8EB',
  '#83D6E9',
  '#C8BFFF',
  '#FFBFDA',
  '#FFB6BA',
  '#FFC19E',
  '#D5DF9A',
  '#B8DF9A',
  '#9ADFAD',
  '#B1F1FF',
  '#84B5FF',
  '#8491FF',
  '#AA8CFF',
  '#FFB8FC',
  '#FF9595',
]

const usersColors = computed<{ [userId: string]: string }>(
  () =>
    props.project.users?.reduce(
      (a, u, i) => ({ ...a, [u.id]: availableColors[i] }),
      {},
    ) ?? {},
)

const canDeleteMember = () => {
  return true
}

function buildUserFullname(user: User) {
  if (!user) return ''
  let fullName = ''
  if (user.firstName) {
    fullName += user.firstName
  }
  if (user.lastName) {
    fullName += ' ' + user.lastName
  }
  if (!fullName) {
    fullName = user.email
  }
  return fullName
}
</script>
