<template>
  <div>
    <FloatingMenu placement="bottom">
      <template #activator="{ show }">
        <div v-tooltip="study.oxfordLevelOfEvidence">
          <StudyActionButton
            class="w-[65px]"
            :state="
              study.state === StudyState.INCLUDED &&
              !!study.appraisal &&
              study.oxfordLevelOfEvidence
                ? ButtonState.appraised
                : study.state !== StudyState.INCLUDED
                  ? ButtonState.disabled
                  : ButtonState.inactive
            "
            :is-review-read-only="review.isLocked.value"
            @click="show()"
          >
            <p v-if="study.oxfordLevelOfEvidence">
              {{ study.oxfordLevelOfEvidence }}
            </p>
            <p v-else>LOE</p>
          </StudyActionButton>
        </div>
      </template>
      <template #content="{ hide }">
        <div>
          <button
            v-for="level in oxfordLevelOfEvidence"
            :key="level"
            class="flex justify-evenly items-center w-full divide-y divide-gray-200"
            :class="
              study.oxfordLevelOfEvidence === level
                ? 'bg-slate-500 text-white '
                : 'hover:bg-gray-200'
            "
            @click="
              study.oxfordLevelOfEvidence === level
                ? clearStudyOxfordLevelOfEvidence(study.id, hide)
                : setOxfordLevelOfEvidenceStudy(study.id, level, hide)
            "
          >
            <p class="px-4 py-2 text-left font-bold">
              {{ level }}
            </p>
          </button>
        </div>
      </template>
    </FloatingMenu>
  </div>
</template>
<script setup lang="ts">
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ButtonState, SnackbarState, StudyState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewItem } from '@core/domain/models/reviewItem.model'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { HttpException } from '@core/exceptions/http.exception'
import StudyActionButton from '../StudyActionButton.vue'

defineProps<{
  study: ReviewItem
}>()
const review = injectStrict(ReviewKey)

const oxfordLevelOfEvidence = OxfordLevelOfEvidenceType
const snackbar = useSnackbar()
const loading = useLoading()

async function setOxfordLevelOfEvidenceStudy(
  studyId: number,
  oxfordLevelOfEvidence: OxfordLevelOfEvidenceType,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.setOxfordLevelOfEvidenceStudy(studyId, oxfordLevelOfEvidence)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study oxford level of evidence updated',
    )
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

async function clearStudyOxfordLevelOfEvidence(
  studyId: number,
  cb: () => void = () => {},
) {
  try {
    loading.start()
    await review.clearStudyOxfordLevelOfEvidence(studyId)
    snackbar.show(
      SnackbarState.SUCCESS,
      'Study oxford level of evidence cleared',
    )
    cb()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
