<template>
  <div class="flex-grow h-full">
    <div class="text-lg">
      The goal is to identify the following information concerning
      <input
        v-model="preset.alternativeName"
        type="text"
        class="flex-1 pl-1 focus:outline-none border-b-2 min-w-[350px] py-2 transition-colors"
        :class="[isFocused ? 'border-black' : 'border-black/30']"
        placeholder="Type the alternative here and press enter"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { CreateReviewKey } from '../../useCreateProjectReview'
import { injectStrict } from '@app/utils/injectStrict'
const { preset } = injectStrict(CreateReviewKey)

const isFocused = ref()
</script>
