import { ProjectsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'
import { ProjectsService } from '@core/application/projects.service'
import { AutoImportCompletedEvent } from '@core/domain/events/autoImportCompleted.event'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { EventDispatcherKey } from '@infrastructure/eventDispatcher/eventDispatcher'
import { computed, InjectionKey, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export const CreateReviewKey: InjectionKey<ReturnType<typeof useCreateReview>> =
  Symbol('CreateReview')

export enum CreateReviewStep {
  PresetSelection,
  PmsDeviceLevelSelection,
  PmsDeviceLevelMinimal,
  PmsSimilarDeviceLevelSelection,
  PmsSimilarDeviceLevelMinimal,
  PmsSimilarDeviceFsnCountrySelection,
  genericDeviceGroupConfiguration,
  MedicalAlternativeConfiguration,
  MedicalBackgroundConfiguration,
  Summary,
}

export type CreatePresetModel = {
  preset: ReviewPreset
  deviceName: string
  manufacturerName: string
  purpose: string
  problem: string
  problemName: string
  alternativeName: string
  alternativeDeviceNames: string[]
  alternativeManufacturerNames: string[]
  fsnCountries: string[]
}

export enum PmcLevel {
  none = 'none',
  minimal = 'minimal',
  standard = 'standard',
  advanced = 'advanced',
}

export enum HasAlternativeNames {
  Na,
  Yes,
  No,
}

export const reviewPresetsMap = {
  [ReviewPreset.MEDICAL_BACKGROUND]: {
    title: 'State of the art',
    subtitle: 'Medical background',
    description:
      'This review provides a comprehensive summary of the medical context, including prevalence and relevant patient populations for a particular indication or condition, outlining the role of relevant medical interventions (treatment, diagnosis, etc.).',
  },
  [ReviewPreset.GENERIC_DEVICE_GROUP]: {
    title: 'State of the art',
    subtitle: 'Performance and safety of the device-specific intervention',
    description:
      'This systematic review assesses the clinical performance and safety of the medical intervention (treatment, diagnosis, etc.) pertaining to the device under evaluation.',
  },
  [ReviewPreset.MEDICAL_ALTERNATIVE]: {
    title: 'State of the art',
    subtitle: 'Performance and safety of an alternative intervention',
    description:
      'This systematic review assesses the clinical performance and safety of an alternative intervention (treatment, diagnosis, etc.).',
  },
  [ReviewPreset.DEVICE_SPECIFIC_SEARCH]: {
    title: 'Post-market',
    subtitle: 'Device under assessment',
    description:
      'This literature search identifies post-market data on the device under assessment.',
  },
  [ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH]: {
    title: 'Post-market',
    subtitle: 'Similar/equivalent device',
    description:
      'This literature search identifies post-market data on similar and equivalent devices.',
  },

  [ReviewPreset.CUSTOM]: {
    title: 'Custom',
    subtitle: 'Custom review',
    description:
      'This review is tailored to specific requirements and criteria defined by the user, allowing for a flexible and personalized assessment of the medical context or intervention.',
  },
}

export default function useCreateReview(projectId: number) {
  const projectsService: ProjectsService = injectStrict(ProjectsServiceKey)
  const eventDispatcher = injectStrict(EventDispatcherKey)

  const currentStep = ref(CreateReviewStep.PresetSelection)
  const reviewName = ref<string>('')

  const pmcLevel = ref<PmcLevel>(PmcLevel.none)

  const preset = ref<CreatePresetModel>({
    preset: ReviewPreset.DEFAULT,
    deviceName: '',
    manufacturerName: '',
    alternativeDeviceNames: [],
    alternativeManufacturerNames: [],
    alternativeName: '',
    problem: '',
    problemName: '',
    purpose: '',
    fsnCountries: [],
  })

  const openAlexWorkCount = ref(0)

  const deviceHasAlternativeNames = ref<HasAlternativeNames>(
    HasAlternativeNames.Na,
  )
  const manufacturerHasAlternativeNames = ref<HasAlternativeNames>(
    HasAlternativeNames.Na,
  )

  watch(
    () => preset.value.preset,
    (v) => {
      reset(v)
    },
  )

  function reset(presetId: ReviewPreset = ReviewPreset.DEFAULT) {
    preset.value = {
      preset: presetId,
      deviceName: '',
      manufacturerName: '',
      alternativeDeviceNames: [],
      alternativeManufacturerNames: [],
      alternativeName: '',
      problem: '',
      problemName: '',
      purpose: '',
      fsnCountries: [],
    }
    pmcLevel.value = PmcLevel.none
    currentStep.value = CreateReviewStep.PresetSelection
    openAlexWorkCount.value = 0
    deviceHasAlternativeNames.value = HasAlternativeNames.Na
    manufacturerHasAlternativeNames.value = HasAlternativeNames.Na
  }

  const router = useRouter()
  async function createReview() {
    const reviewId = await projectsService.createReview(
      projectId,
      reviewName.value,
      preset.value,
    )

    eventDispatcher.registerHandler(
      AutoImportCompletedEvent,
      (event: AutoImportCompletedEvent) => {
        if (event.reviewId === reviewId) router.push(`/reviews/${reviewId}`)
      },
    )
  }

  function isDeviceNamesValid() {
    return (
      preset.value.deviceName !== '' &&
      (deviceHasAlternativeNames.value === HasAlternativeNames.No ||
        (deviceHasAlternativeNames.value === HasAlternativeNames.Yes &&
          preset.value.alternativeDeviceNames.length > 0))
    )
  }

  function isManufacturerNamesValid() {
    return (
      openAlexWorkCount.value < 500 ||
      (preset.value.manufacturerName !== '' &&
        (manufacturerHasAlternativeNames.value === HasAlternativeNames.No ||
          (manufacturerHasAlternativeNames.value === HasAlternativeNames.Yes &&
            preset.value.alternativeManufacturerNames.length > 0)))
    )
  }

  function isPresetSelectionValid() {
    return preset.value.preset !== ReviewPreset.DEFAULT
  }

  function isPmsDeviceLevelSelectionValid() {
    return pmcLevel.value !== PmcLevel.none
  }

  function isPmsDeviceLevelMinimalValid() {
    return isDeviceNamesValid() && isManufacturerNamesValid()
  }

  function isGenericDeviceGroupConfigurationValid() {
    return preset.value.alternativeName !== ''
  }

  function isMedicalAlternativeConfigurationValid() {
    return preset.value.alternativeName !== ''
  }

  function isMedicalBackgroundConfigurationValid() {
    return (
      preset.value.purpose !== '' &&
      (preset.value.problemName === ''
        ? preset.value.problem === ''
        : preset.value.problem !== '')
    )
  }

  function isSimilarDeviceFsnCountrySelectionValid() {
    return preset.value.fsnCountries.length > 0
  }

  function isSummaryValid() {
    return reviewName.value !== ''
  }

  const isCurrentStepValid = computed<boolean>(() => {
    switch (currentStep.value) {
      case CreateReviewStep.PresetSelection:
        return isPresetSelectionValid()
      case CreateReviewStep.PmsDeviceLevelSelection:
      case CreateReviewStep.PmsSimilarDeviceLevelSelection:
        return isPmsDeviceLevelSelectionValid()
      case CreateReviewStep.PmsDeviceLevelMinimal:
      case CreateReviewStep.PmsSimilarDeviceLevelMinimal:
        return isPmsDeviceLevelMinimalValid()
      case CreateReviewStep.PmsSimilarDeviceFsnCountrySelection:
        return isSimilarDeviceFsnCountrySelectionValid()
      case CreateReviewStep.genericDeviceGroupConfiguration:
        return isGenericDeviceGroupConfigurationValid()
      case CreateReviewStep.MedicalAlternativeConfiguration:
        return isMedicalAlternativeConfigurationValid()
      case CreateReviewStep.MedicalBackgroundConfiguration:
        return isMedicalBackgroundConfigurationValid()
      case CreateReviewStep.Summary:
        return isSummaryValid()
      default:
        return false
    }
  })

  function goToNextStep() {
    if (!isCurrentStepValid.value) return

    switch (currentStep.value) {
      case CreateReviewStep.PresetSelection:
        handlePresetSelection()
        break
      case CreateReviewStep.PmsDeviceLevelSelection:
        goToPmsDeviceLevelMinimalIfNeeded()
        break
      case CreateReviewStep.PmsSimilarDeviceLevelSelection:
        goToPmsSimilarDeviceLevelMinimalIfNeeded()
        break
      case CreateReviewStep.PmsSimilarDeviceLevelMinimal:
        currentStep.value = CreateReviewStep.PmsSimilarDeviceFsnCountrySelection
        break
      case CreateReviewStep.PmsSimilarDeviceFsnCountrySelection:
        currentStep.value = CreateReviewStep.Summary
        break
      default:
        currentStep.value = CreateReviewStep.Summary
    }
  }

  function handlePresetSelection() {
    switch (preset.value.preset) {
      case ReviewPreset.DEVICE_SPECIFIC_SEARCH:
        currentStep.value = CreateReviewStep.PmsDeviceLevelSelection
        break
      case ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH:
        currentStep.value = CreateReviewStep.PmsSimilarDeviceLevelSelection
        break
      case ReviewPreset.GENERIC_DEVICE_GROUP:
        currentStep.value = CreateReviewStep.genericDeviceGroupConfiguration
        break
      case ReviewPreset.MEDICAL_ALTERNATIVE:
        currentStep.value = CreateReviewStep.MedicalAlternativeConfiguration
        break
      case ReviewPreset.MEDICAL_BACKGROUND:
        currentStep.value = CreateReviewStep.MedicalBackgroundConfiguration
        break
      case ReviewPreset.CUSTOM:
        currentStep.value = CreateReviewStep.Summary
        break
    }
  }

  function goToPmsDeviceLevelMinimalIfNeeded() {
    if (pmcLevel.value === PmcLevel.minimal) {
      currentStep.value = CreateReviewStep.PmsDeviceLevelMinimal
    }
  }

  function goToPmsSimilarDeviceLevelMinimalIfNeeded() {
    if (pmcLevel.value === PmcLevel.minimal) {
      currentStep.value = CreateReviewStep.PmsSimilarDeviceLevelMinimal
    }
  }

  function goToPreviousStep() {
    switch (currentStep.value) {
      case CreateReviewStep.PmsDeviceLevelSelection:
      case CreateReviewStep.PmsSimilarDeviceLevelSelection:
        currentStep.value = CreateReviewStep.PresetSelection
        break
      case CreateReviewStep.PmsDeviceLevelMinimal:
        currentStep.value = CreateReviewStep.PmsDeviceLevelSelection
        break
      case CreateReviewStep.PmsSimilarDeviceLevelMinimal:
        currentStep.value = CreateReviewStep.PmsSimilarDeviceLevelSelection
        break
      case CreateReviewStep.PmsSimilarDeviceFsnCountrySelection:
        currentStep.value = CreateReviewStep.PmsSimilarDeviceLevelMinimal
        break
      case CreateReviewStep.MedicalAlternativeConfiguration:
      case CreateReviewStep.MedicalBackgroundConfiguration:
      case CreateReviewStep.genericDeviceGroupConfiguration:
        currentStep.value = CreateReviewStep.PresetSelection
        break
      case CreateReviewStep.Summary:
        handleSummaryPreviousStep()
        break
    }
  }

  function handleSummaryPreviousStep() {
    switch (preset.value.preset) {
      case ReviewPreset.DEVICE_SPECIFIC_SEARCH:
        goToPmsDeviceLevelMinimalIfNeeded()
        break
      case ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH:
        currentStep.value = CreateReviewStep.PmsSimilarDeviceFsnCountrySelection
        break
      case ReviewPreset.GENERIC_DEVICE_GROUP:
        currentStep.value = CreateReviewStep.genericDeviceGroupConfiguration
        break
      case ReviewPreset.MEDICAL_ALTERNATIVE:
        currentStep.value = CreateReviewStep.MedicalAlternativeConfiguration
        break
      case ReviewPreset.MEDICAL_BACKGROUND:
        currentStep.value = CreateReviewStep.MedicalBackgroundConfiguration
        break
      case ReviewPreset.CUSTOM:
        currentStep.value = CreateReviewStep.PresetSelection
        break
    }
  }

  function toggleCountry(countryCode: string) {
    const index = preset.value.fsnCountries.indexOf(countryCode)
    if (index === -1) {
      preset.value.fsnCountries.push(countryCode)
    } else {
      preset.value.fsnCountries.splice(index, 1)
    }
  }

  return {
    preset,
    reviewName,
    reset,
    createReview,
    currentStep,
    goToNextStep,
    goToPreviousStep,
    pmcLevel,
    openAlexWorkCount,
    isCurrentStepValid,
    deviceHasAlternativeNames,
    manufacturerHasAlternativeNames,
    toggleCountry,
  }
}
