import { Id } from '@core/domain/types/id.type'
import { useStorage } from '@vueuse/core'

export default () => {
  const expandedProjectsId = useStorage<number[]>('expandedProjects', [])

  function toggleProjectExpansion(projectId: Id) {
    const index = expandedProjectsId.value.findIndex((pId) => pId === projectId)
    if (index > -1) expandedProjectsId.value.splice(index, 1)
    else expandedProjectsId.value.push(projectId)
  }
  return { expandedProjectsId, toggleProjectExpansion }
}
