import { linkHorizontal, linkVertical, select } from 'd3'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { toPng } from 'html-to-image'
import { Review } from '@core/domain/models/review.model'
import { ImportSource } from '@core/domain/models/import-source.model'
import { FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE } from '@core/domain/types/full-text-cannot-be-retrieved-screening-criterion.type'

let width = 0
let height = 0

export async function generatePrismaDiagram(review: Review): Promise<string> {
  let databases: ImportSource[] = []
  let otherSources: ImportSource[] = []

  review.plan?.importPlan.importSources?.forEach((i) => {
    if (i.type === ImportSourceType.DATABASE) {
      databases.push(i)
    } else if (
      i.type === ImportSourceType.OTHER_SOURCE ||
      i.type === ImportSourceType.FIELD_SAFETY_NOTICES
    ) {
      otherSources.push(i)
    }
  })

  databases = [...new Set(databases)]
  otherSources = [...new Set(otherSources)]
  const data = [
    {
      id: 1,
      x: 0,
      y: 0,
      text: [
        `Records identified (n = ${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.total ?? 0
        })`,
        ...(databases.length > 0
          ? [
              `Databases (n = ${
                review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.total
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${review.stats.sources[i.id].total})`,
              ),
            ]
          : []),
      ],
      relations: [2, 4],
    },
    {
      id: 2,
      x: 1,
      y: 0,
      text: [
        `Records removed before screening (n =${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.duplicate ?? 0
        })`,
        `Duplicate records removed  (n =${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.duplicate ?? 0
        })`,
        ...(databases.length > 0
          ? [
              `Databases (n = ${
                review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.duplicate
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].duplicate
                  })`,
              ),
            ]
          : []),
      ],
      relations: [],
    },
    {
      id: 3,
      x: 2,
      y: 0,
      text: [
        `Records identified (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]?.total ??
            0) +
          (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
            ?.total ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]?.total ??
            0) +
          (review.stats.sourceTypes?.[ImportSourceType.FIELD_SAFETY_NOTICES]
            ?.total ?? 0)
        })`,
        ...((review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]?.total ??
          0) > 0
          ? [
              `Hand search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]?.total
              })`,
            ]
          : []),
        `Citation search (n = ${
          review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]?.total ??
          0
        })`,
        ...(otherSources.length > 0
          ? [
              `Other sources (n = ${
                (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
                  ?.total ?? 0) +
                (review.stats.sourceTypes?.[
                  ImportSourceType.FIELD_SAFETY_NOTICES
                ]?.total ?? 0)
              })`,
              ...otherSources.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${review.stats.sources[i.id].total})`,
              ),
            ]
          : []),
      ],
      relations: [8],
    },
    {
      id: 4,
      x: 0,
      y: 1,
      text: [
        `Records screened (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.total ?? 0) -
          (review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.duplicate ??
            0)
        })`,
        ...(databases.length > 0
          ? [
              `Databases (n = ${
                (review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.total ??
                  0) -
                (review.stats.sourceTypes?.[ImportSourceType.DATABASE]
                  ?.duplicate ?? 0)
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].total -
                    review.stats.sources[i.id].duplicate
                  })`,
              ),
            ]
          : []),
      ],
      relations: [5, 6],
    },
    {
      id: 5,
      x: 1,
      y: 1,
      text: [
        `Records excluded (n = ${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]
            ?.titleAndAbstractExcludedScreeningTotal ?? 0
        })`,
        ...(review.plan?.screeningPlan.titleAndAbstractCriteria?.map(
          (k: string) => {
            const value =
              review.stats.sourceTypes?.[ImportSourceType.DATABASE]
                ?.titleAndAbstractExcludedScreening?.[k] ?? 0
            return `${k} (n = ${value})`
          },
        ) ?? []),
      ],
      relations: [],
    },
    {
      id: 6,
      x: 0,
      y: 2,
      text: [
        `Records sought for retrieval (n = ${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]
            ?.soughtForRetrieval ?? 0
        })`,

        ...(databases.length > 0
          ? [
              `Databases (n = ${
                review.stats.sourceTypes?.[ImportSourceType.DATABASE]
                  ?.soughtForRetrieval
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].soughtForRetrieval
                  })`,
              ),
            ]
          : []),
      ],
      relations: [7, 10],
    },
    {
      id: 7,
      x: 1,
      y: 2,
      text: [
        `Records not retrieved (n = ${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]
            ?.reportsNotRetrieved ?? 0
        })`,

        ...(databases.length > 0
          ? [
              `Databases (n = ${
                review.stats.sourceTypes?.[ImportSourceType.DATABASE]
                  ?.reportsNotRetrieved
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].reportsNotRetrieved
                  })`,
              ),
            ]
          : []),
      ],
      relations: [],
    },
    {
      id: 8,
      x: 2,
      y: 2,
      text: [
        `Records sought for retrieval (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
            ?.soughtForRetrieval ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
            ?.soughtForRetrieval ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
            ?.soughtForRetrieval ?? 0)
        })`,

        ...((review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
          ?.soughtForRetrieval ?? 0) > 0
          ? [
              `Hand search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
                  ?.soughtForRetrieval
              })`,
            ]
          : []),

        ...((review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
          ?.soughtForRetrieval ?? 0) > 0
          ? [
              `Citation search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
                  ?.soughtForRetrieval
              })`,
            ]
          : []),

        ...(otherSources.length > 0
          ? [
              `Other sources (n = ${
                (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
                  ?.soughtForRetrieval ?? 0) +
                (review.stats.sourceTypes?.[
                  ImportSourceType.FIELD_SAFETY_NOTICES
                ]?.soughtForRetrieval ?? 0)
              })`,
              ...otherSources.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].soughtForRetrieval
                  })`,
              ),
            ]
          : []),
      ],
      relations: [9, 12],
    },
    {
      id: 9,
      x: 3,
      y: 2,
      text: [
        `Records not retrieved (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
            ?.reportsNotRetrieved ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
            ?.reportsNotRetrieved ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
            ?.reportsNotRetrieved ?? 0)
        })`,

        ...((review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
          ?.reportsNotRetrieved ?? 0) > 0
          ? [
              `Hand search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
                  ?.reportsNotRetrieved
              })`,
            ]
          : []),

        ...((review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
          ?.reportsNotRetrieved ?? 0) > 0
          ? [
              `Citation search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
                  ?.reportsNotRetrieved
              })`,
            ]
          : []),

        ...(otherSources.length > 0
          ? [
              `Other sources (n = ${
                (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
                  ?.reportsNotRetrieved ?? 0) +
                (review.stats.sourceTypes?.[
                  ImportSourceType.FIELD_SAFETY_NOTICES
                ]?.reportsNotRetrieved ?? 0)
              })`,
              ...otherSources.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].reportsNotRetrieved
                  })`,
              ),
            ]
          : []),
      ],
      relations: [],
    },
    {
      id: 10,
      x: 0,
      y: 3,
      text: [
        `Records assessed for eligibility (n = ${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]
            ?.assessedForEligibility ?? 0
        })`,

        ...(databases.length > 0
          ? [
              `Databases (n = ${
                review.stats.sourceTypes?.[ImportSourceType.DATABASE]
                  ?.assessedForEligibility
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].assessedForEligibility
                  })`,
              ),
            ]
          : []),
      ],
      relations: [11, 14],
    },
    {
      id: 11,
      x: 1,
      y: 3,
      text: [
        `Records excluded (n = ${
          review.stats.sourceTypes?.[ImportSourceType.DATABASE]
            ?.fullTextExcludedScreeningTotal ?? 0
        })`,
        ...(review.plan?.screeningPlan.fullTextCriteria
          .concat(FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE)
          ?.map((k: string) => {
            const value =
              review.stats.sourceTypes?.[ImportSourceType.DATABASE]
                ?.fullTextExcludedScreening[k] ?? 0
            return `${k} (n = ${value})`
          }) ?? []),
      ],
      relations: [],
    },
    {
      id: 12,
      x: 2,
      y: 3,
      text: [
        `Records assessed for eligibility (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
            ?.assessedForEligibility ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
            ?.assessedForEligibility ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
            ?.assessedForEligibility ?? 0)
        })`,

        ...((review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
          ?.assessedForEligibility ?? 0) > 0
          ? [
              `Hand search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
                  ?.assessedForEligibility
              })`,
            ]
          : []),

        ...((review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
          ?.assessedForEligibility ?? 0) > 0
          ? [
              `Citation search (n = ${
                review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
                  ?.assessedForEligibility
              })`,
            ]
          : []),

        ...(otherSources.length > 0
          ? [
              `Other sources (n = ${
                review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
                  ?.assessedForEligibility ??
                review.stats.sourceTypes?.[
                  ImportSourceType.FIELD_SAFETY_NOTICES
                ]?.assessedForEligibility
              })`,
              ...otherSources.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].assessedForEligibility
                  })`,
              ),
            ]
          : []),
      ],
      relations: [13, 14],
    },
    {
      id: 13,
      x: 3,
      y: 3,
      text: [
        `Records excluded: (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
            ?.fullTextExcludedScreeningTotal ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
            ?.fullTextExcludedScreeningTotal ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
            ?.fullTextExcludedScreeningTotal ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.FIELD_SAFETY_NOTICES]
            ?.fullTextExcludedScreeningTotal ?? 0)
        })`,
        ...(review.plan?.screeningPlan.fullTextCriteria
          .concat(FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE)
          ?.map((k: string) => {
            const value =
              (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]
                ?.fullTextExcludedScreening[k] ?? 0) +
              (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
                ?.fullTextExcludedScreening[k] ?? 0) +
              (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
                ?.fullTextExcludedScreening[k] ?? 0) +
              (review.stats.sourceTypes?.[ImportSourceType.FIELD_SAFETY_NOTICES]
                ?.fullTextExcludedScreening[k] ?? 0)
            return `${k} (n = ${value})`
          }) ?? []),
      ],
      relations: [],
    },
    {
      id: 14,
      x: 0,
      y: 4,
      text: [
        `Studies included in review (n = ${review.stats.overall.included})`,

        ...(databases.length > 0
          ? [
              `Databases (n = ${
                review.stats.sourceTypes?.[ImportSourceType.DATABASE]?.included
              })`,
              ...databases.map(
                (i: ImportSource) =>
                  `\u{2022} ${i.name} (n = ${
                    review.stats.sources[i.id].included
                  })`,
              ),
            ]
          : []),

        `Other Methods (n = ${
          (review.stats.sourceTypes?.[ImportSourceType.HAND_SEARCH]?.included ??
            0) +
          (review.stats.sourceTypes?.[ImportSourceType.CITATION_SEARCH]
            ?.included ?? 0) +
          (review.stats.sourceTypes?.[ImportSourceType.OTHER_SOURCE]
            ?.included ?? 0)
        })`,
      ],
      relations: [],
    },
  ]

  const svg = select('#off-screen').append('svg').style('position', 'absolute')
  svg
    .append('rect')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('fill', 'white')
  const mainSvg = svg.append('svg').attr('x', 80).attr('y', 80)

  const g = mainSvg
    .selectAll('mainSvg')
    .data(data)
    .enter()
    .append('svg')
    .attr('id', (d) => `svg-${d.id}`)

  const rects = g
    .append('rect')
    .attr('fill', 'transparent')
    .attr('stroke', '#000')
  const textg = g.append('g').attr('transform-origin', '50% 50%')

  const legendsSvg = svg.append('svg')

  textg.each(function (d, i, t) {
    const textg = select(t[i])
    d.text.forEach((line, j) => {
      textg
        .append('text')
        .text(line)
        .attr('fill', '#000')
        .attr('dy', `${j}em`)
        .attr('x', 0)
        .attr('text-anchor', 'middle')
        .style('font-weight', j === 0 ? 'bold' : 'normal')
        .call(wrap, 400) // Set max width to 200
    })
  })

  function wrap(
    text: d3.Selection<SVGTextElement, unknown, null, undefined>,
    width: number,
  ) {
    text.each(function () {
      const text = select(this),
        words = text.text().split(/\s+/).reverse(),
        lineHeight = 1.1, // ems
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy')),
        x = text.attr('x')
      let word,
        line: string[] = [],
        lineNumber = 0,
        tspan = text
          .text(null)
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', dy + 'em')
      while ((word = words.pop())) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node()!.getComputedTextLength() > width) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word)
        }
      }
    })
  }

  const maxSize = [0, 0]
  textg.each(function (_d, i, t) {
    const bbox = t[i]?.getBBox()
    if (bbox?.width > maxSize[0]) {
      maxSize[0] = bbox.width
    }
    if (bbox?.height > maxSize[1]) {
      maxSize[1] = bbox.height
    }
  })

  width = 30 + maxSize[0] * 4 + 80 * 4
  height = 30 + maxSize[1] * 5 + 80 * 5

  svg.attr('width', width).attr('height', height)
  mainSvg.attr('width', width).attr('height', height)
  rects
    .attr('width', maxSize[0] + 20)
    .attr('height', maxSize[1] + 20)
    .attr('id', (_d, i) => `rect-${i}`)
    .attr('x', (d) => d.x * maxSize[0] + 80 * d.x)
    .attr('y', (d) => d.y * maxSize[1] + 80 * d.y)

  const identificationLegend = legendsSvg
    .append('svg')
    .attr('width', 42)
    .attr('height', maxSize[1] + 20)
    .attr('x', 0 * maxSize[1] + 10)
    .attr('y', 0 * maxSize[1] + 80)

  identificationLegend
    .append('rect')
    .attr('fill', '#9ac4e5')
    .attr('stroke', '#000')
    .attr('width', 42)
    .attr('height', maxSize[1] + 20)
    .attr('x', 0)
    .attr('y', 0)
    .attr('rx', 6)
    .attr('ry', 6)

  identificationLegend
    .append('text')
    .text('Identification')
    .attr('fill', '#000')
    .attr(
      'x',
      (_d, i, t) =>
        -((maxSize[1] - t[i].getBBox().width) / 2 + t[i].getBBox().width + 10),
    )
    .attr(
      'y',
      (_d, i, t) => (42 - t[i].getBBox().height) / 4 + t[i].getBBox().height,
    )
    .attr('transform', 'rotate(-90)')

  const screeningLegend = legendsSvg
    .append('svg')
    .attr('width', 42)
    .attr('height', 3 * maxSize[1] + 3 * 20 + 2 * 60)
    .attr('x', 0 * maxSize[1] + 10)
    .attr('y', 1 * maxSize[1] + 2 * 80)

  screeningLegend
    .append('rect')
    .attr('fill', '#9ac4e5')
    .attr('stroke', '#000')
    .attr('width', 42)
    .attr('height', 3 * maxSize[1] + 3 * 20 + 2 * 60)
    .attr('x', 0)
    .attr('y', 0)
    .attr('rx', 6)
    .attr('ry', 6)

  screeningLegend
    .append('text')
    .text('Screening')
    .attr('fill', '#000')
    .attr(
      'x',
      (_d, i, t) =>
        -(
          3 *
          ((maxSize[1] - t[i].getBBox().width) / 2 + t[i].getBBox().width + 10)
        ),
    )
    .attr(
      'y',
      (_d, i, t) => (42 - t[i].getBBox().height) / 4 + t[i].getBBox().height,
    )
    .attr('transform', 'rotate(-90)')

  const includedLegend = legendsSvg
    .append('svg')
    .attr('width', 42)
    .attr('height', maxSize[1] + 20)
    .attr('x', 0 * maxSize[1] + 10)
    .attr('y', 4 * maxSize[1] + 5 * 80)

  includedLegend
    .append('rect')
    .attr('fill', '#9ac4e5')
    .attr('stroke', '#000')
    .attr('width', 42)
    .attr('height', maxSize[1] + 20)
    .attr('x', 0)
    .attr('y', 0)
    .attr('rx', 6)
    .attr('ry', 6)

  includedLegend
    .append('text')
    .text('Included')
    .attr('fill', '#000')
    .attr(
      'x',
      (_d, i, t) =>
        -((maxSize[1] - t[i].getBBox().width) / 2 + t[i].getBBox().width + 10),
    )
    .attr(
      'y',
      (_d, i, t) => (42 - t[i].getBBox().height) / 4 + t[i].getBBox().height,
    )
    .attr('transform', 'rotate(-90)')

  const databasesLegend = legendsSvg
    .append('svg')
    .attr('width', 2 * maxSize[0] + 100)
    .attr('height', 42)
    .attr('x', 0 * maxSize[0] + 80)
    .attr('y', 0 * maxSize[1] + 10)

  databasesLegend
    .append('rect')
    .attr('fill', '#ffc000')
    .attr('stroke', '#000')
    .attr('width', 2 * maxSize[0] + 100)
    .attr('height', 42)
    .attr('x', 0)
    .attr('y', 0)
    .attr('rx', 6)
    .attr('ry', 6)

  databasesLegend
    .append('text')
    .text('Identification of studies via databases and registers')
    .attr('fill', '#000')
    .attr(
      'x',
      (_d, i, t) =>
        (2 * maxSize[0] - t[i].getBBox().width) / 4 + t[i].getBBox().width / 4,
    )
    .attr(
      'y',
      (_d, i, t) => (42 - t[i].getBBox().height) / 4 + t[i].getBBox().height,
    )
  const otherSourcesAndHandSearchLegend = legendsSvg
    .append('svg')
    .attr('width', 2 * maxSize[0] + 100)
    .attr('height', 42)
    .attr('x', 2 * maxSize[0] + 3 * 80)
    .attr('y', 0 * maxSize[1] + 10)

  otherSourcesAndHandSearchLegend
    .append('rect')
    .attr('fill', '#ffc000')
    .attr('stroke', '#000')
    .attr('width', 2 * maxSize[0] + 100)
    .attr('height', 42)
    .attr('x', 0)
    .attr('y', 0)
    .attr('rx', 6)
    .attr('ry', 6)

  otherSourcesAndHandSearchLegend
    .append('text')
    .text('Identification of studies via other methods')
    .attr('fill', '#000')
    .attr(
      'x',
      (_d, i, t) =>
        (2 * maxSize[0] - t[i].getBBox().width) / 4 + t[i].getBBox().width / 2,
    )
    .attr(
      'y',
      (_d, i, t) => (42 - t[i].getBBox().height) / 4 + t[i].getBBox().height,
    )

  textg.attr('transform', (_d, i, g) => {
    const rect = select(`#rect-${i}`)
    const translateX =
      parseInt(rect.attr('x')) + parseInt(rect.attr('width')) / 2
    const translateY =
      parseInt(rect.attr('y')) +
      15 +
      (parseInt(rect.attr('height')) - g[i].getBBox().height) / 2

    return `translate( ${translateX},${translateY})`
  })

  g.each(function (d, i, t) {
    d.relations.forEach((id) => {
      const relatedNodeIndex = data.findIndex((d) => d.id === id)
      let link: string | null
      if (d.y === data[relatedNodeIndex].y) {
        link = linkHorizontal()({
          source: [
            t[i].getBBox().x + t[i].getBBox().width,
            t[i].getBBox().y + t[i].getBBox().height / 2,
          ],
          target: [
            t[relatedNodeIndex].getBBox().x,
            t[relatedNodeIndex].getBBox().y +
              t[relatedNodeIndex].getBBox().height / 2,
          ],
        })
      } else if (d.x === data[relatedNodeIndex].x) {
        link = linkVertical()({
          source: [
            t[i].getBBox().x + t[i].getBBox().width / 2,
            t[i].getBBox().y + t[i].getBBox().height,
          ],
          target: [
            t[relatedNodeIndex].getBBox().x +
              t[relatedNodeIndex].getBBox().width / 2,
            t[relatedNodeIndex].getBBox().y,
          ],
        })
      } else {
        const linkV = linkVertical()({
          source: [
            t[i].getBBox().x + t[i].getBBox().width / 2,
            t[i].getBBox().y + t[i].getBBox().height,
          ],
          target: [
            t[i].getBBox().x + t[i].getBBox().width / 2,
            t[relatedNodeIndex].getBBox().y +
              t[relatedNodeIndex].getBBox().height / 2,
          ],
        })

        const linkH = linkHorizontal()({
          source: [
            t[i].getBBox().x + t[i].getBBox().width / 2,
            t[relatedNodeIndex].getBBox().y +
              t[relatedNodeIndex].getBBox().height / 2,
          ],
          target: [
            t[relatedNodeIndex].getBBox().x +
              t[relatedNodeIndex].getBBox().width,
            t[relatedNodeIndex].getBBox().y +
              t[relatedNodeIndex].getBBox().height / 2,
          ],
        })
        link = linkV! + linkH
      }
      mainSvg
        .append('svg:defs')
        .append('svg:marker')
        .attr('id', 'triangle')
        .attr('refX', 11)
        .attr('refY', 6)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('markerUnits', 'userSpaceOnUse')
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M 0 0 12 6 0 12 3 6')
        .style('fill', 'black')

      mainSvg
        .append('path')
        .attr('marker-end', 'url(#triangle)')
        .attr('d', link)
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr('fill', 'black')
    })
  })

  return new Promise((resolve) => {
    toPng(svg.node()! as unknown as HTMLElement).then((url) => {
      resolve(url)
      svg.remove()
    })
  })
}
