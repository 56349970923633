import { StudyState } from '@app/types'
import { Review } from '@core/domain/models/review.model'
import { computed } from 'vue'

export default (review: Review) => {
  const areAllStudiesScreened = computed(
    () =>
      review?.studies?.every(
        (study) => study.state !== StudyState.UNSCREENED,
      ) ?? false,
  )

  const areAllIncludedStudiesAppraised = computed(() => {
    const includedStudies = review?.studies.filter(
      (study) => study.state === StudyState.INCLUDED,
    )
    if (
      review?.plan?.appraisalPlan.isImdrfMdce2019Applicable &&
      review?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable
    )
      return includedStudies.every(
        (study) =>
          study.appraisal?.['D'] != null &&
          study.appraisal?.['A'] != null &&
          study.appraisal?.['P'] != null &&
          study.appraisal?.['R'] != null &&
          study.appraisal?.['T'] != null &&
          study.appraisal?.['O'] != null &&
          study.appraisal?.['F'] != null &&
          study.appraisal?.['S'] != null &&
          study.appraisal?.['C'] != null &&
          study.oxfordLevelOfEvidence,
      )
    else if (review?.plan?.appraisalPlan.isOxfordLevelOfEvidenceApplicable)
      return includedStudies.every((study) => study.oxfordLevelOfEvidence)
    else if (review?.plan?.appraisalPlan.isImdrfMdce2019Applicable)
      return includedStudies.every(
        (study) =>
          study.appraisal?.['D'] != null &&
          study.appraisal?.['A'] != null &&
          study.appraisal?.['P'] != null &&
          study.appraisal?.['R'] != null &&
          study.appraisal?.['T'] != null &&
          study.appraisal?.['O'] != null &&
          study.appraisal?.['F'] != null &&
          study.appraisal?.['S'] != null &&
          study.appraisal?.['C'] != null,
      )
    else return true
  })

  const areAllStudiesProcessed = computed(
    () => areAllIncludedStudiesAppraised.value && areAllStudiesScreened.value,
  )

  return {
    areAllStudiesScreened,
    areAllIncludedStudiesAppraised,
    areAllStudiesProcessed,
  }
}
