<template>
  <tr>
    <td class="border-b border-black/20 px-4 py-2">
      <InlineTextInput
        ref="roleInput"
        :model-value="evaluatorGroup.role"
        :disabled="review.isPlanReadonly.value"
        placeholder="Role"
        class="h-full"
        editor-classes="h-full"
        multiline
        @save="(v: string) => emit('updateRoleName', evaluatorGroup, v)"
      />
    </td>
    <td class="border-b border-black/20 px-4 py-2">
      <div class="flex flex-wrap gap-1 items-center">
        <div
          v-for="member in evaluatorGroup.members"
          :key="member"
          class="rounded-full bg-blue-100 dark:text-white text-black text-center max-w-fit py-1 pr-3 pl-1.5 flex gap-1.5 items-center whitespace-nowrap"
        >
          <div
            class="w-5 h-5 p-1 rounded-full text-xs flex items-center justify-center"
          >
            <p class="h-3 w-3 font-medium leading-3">
              {{ member[0].toUpperCase() }}
            </p>
          </div>
          <div class="leading-none">
            {{ member }}
          </div>
          <button
            @click="emit('removeMemberFromGroup', member, evaluatorGroup)"
          >
            <XIcon class="w-4 h-4" />
          </button>
        </div>
        <EvaluatorsCombobox
          v-if="!review.isPlanReadonly.value && evaluatorGroup.role"
          ref="evaluatorRefs"
          v-model="clearValue"
          :role="evaluatorGroup.role"
          @update:model-value="
            (user) => {
              if (user) emit('addMemberToGroup', user, evaluatorGroup)
            }
          "
          @new-item="emit('addMemberToGroup', $event, evaluatorGroup)"
        />
      </div>
    </td>
    <td class="border-b border-black/20 px-4 py-2 text-right">
      <button @click="emit('removeGroup', evaluatorGroup)">
        <TrashIcon class="w-4 h-4 text-red-600" />
      </button>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { ReviewKey } from '../../use-review'
import { injectStrict } from '@app/utils/injectStrict'
import EvaluatorsCombobox from './EvaluatorsCombobox.vue'
import { nextTick, onMounted, ref, useTemplateRef } from 'vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { EvaluatorGroup } from '@core/domain/models/evaluatorGroup.model'

const review = injectStrict(ReviewKey)
const clearValue = ref('')
const roleInput = useTemplateRef('roleInput')
const props = defineProps<{
  evaluatorGroup: { role: string; members: string[]; id: number }
}>()

onMounted(() => {
  nextTick(() => {
    if (props.evaluatorGroup.role === '') roleInput.value?.startEditMode()
  })
})

const emit = defineEmits<{
  (e: 'removeMemberFromGroup', member: string, group: EvaluatorGroup): void
  (e: 'addMemberToGroup', member: string, group: EvaluatorGroup): void
  (e: 'updateRoleName', goup: EvaluatorGroup, newRole: string): void
  (e: 'removeGroup', goup: EvaluatorGroup): void
}>()
</script>
