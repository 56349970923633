<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="text-sm text-slate-700 font-medium pointer-events-none"
    >
      {{ label }}
      <span v-if="isRequired" class="text-red-600">*</span>
    </label>
    <Datepicker
      v-model="value"
      model-type="yyyy-MM-dd"
      :format="format"
      :max-date="new Date()"
      auto-apply
      :enable-time-picker="false"
      :placeholder="placeholder"
      :clearable="false"
      class="evidence-datepicker"
      :range="range"
      :multi-calendars="range"
      :ui="{
        input:
          '!outline-none !w-full !rounded-md border-slate-300 py-1 border hover:border-blue-500  bg-white',
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import '@vuepic/vue-datepicker/dist/main.css'
import Datepicker from '@vuepic/vue-datepicker'
import { computed, ref } from 'vue'
import { useSettings } from '@app/composables/use-settings'
import { v4 as uuidv4 } from 'uuid'

const id = uuidv4()

const settings = useSettings()
const props = withDefaults(
  defineProps<{
    rules?: ((v: string | string[] | undefined) => boolean | string)[]
    label?: string
    class?: string
    placeholder: string
    error?: string
    isRequired?: boolean
    range?: boolean
  }>(),
  {
    class: '',
    rules: () => [],
    placeholder: '',
    label: '',
    error: '',
    isRequired: false,
    range: false,
  },
)

const value = defineModel<string | string[]>()
if (props.range) value.value = ['', '']

const format = computed(() => {
  return settings.dateFormat.value
})

const internalError = ref<string>()

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  internalError.value = errors.join(' ')
  return errors.length <= 0
}

function reset() {
  value.value = ''
  setTimeout(() => (internalError.value = ''), 0)
}

defineExpose({
  validate,
  reset,
})
</script>
<style>
input.dp__input {
  font-family: 'Inter', sans-serif;
}
</style>
