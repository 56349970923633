<template>
  <div
    class="fixed bottom-5 flex justify-center w-full"
    style="z-index: 999999999999999; pointer-events: none"
  >
    <transition v-if="snackbar.isShown.value" name="slide-fade" mode="out-in">
      <div
        v-if="snackbar.state.value === SnackbarState.SUCCESS"
        class="rounded-md bg-green-50 p-4 min-w-[800px]"
        style="pointer-events: auto"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <CheckCircleIcon
              class="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-green-800">
              {{ snackbar.content.value }}
            </p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button
                type="button"
                class="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                @click="snackbar.hide"
              >
                <span class="sr-only">Dismiss</span>
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="snackbar.state.value === SnackbarState.ERROR"
        class="rounded-md bg-red-50 p-4 min-w-[800px]"
        style="pointer-events: auto"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-red-800">
              {{ snackbar.content.value }}
            </p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button
                type="button"
                class="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                @click="snackbar.hide"
              >
                <span class="sr-only">Dismiss</span>
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import {
  CheckCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from '@heroicons/vue/20/solid'

const snackbar = useSnackbar()
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
  transform: translateY(100%);
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
  transform: translateY(100%);
}

.slide-fade-enter-to {
  transform: translateY(0);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
