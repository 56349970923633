import { User } from '@core/domain/models/user.model'
import { Api } from './review-manager-api'
import { Role } from '@core/domain/types/role.type'

export class UsersRepository {
  constructor(private _reviewManagerService: Api<unknown>) {}
  async create(params: {
    email: string
    role: Role
    password: string
    firstName: string
    lastName: string
  }): Promise<User> {
    const { data } = await this._reviewManagerService.api.usersControllerCreate(
      {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        role: params.role,
        password: params.password,
      },
    )
    return new User({ ...data, role: data.role as Role })
  }

  async find(): Promise<User[]> {
    const { data } = await this._reviewManagerService.api.usersControllerFind()
    return data.map((d: unknown) => new User(d as Partial<User>))
  }

  async findCurrentUser(): Promise<User> {
    const { data } =
      await this._reviewManagerService.api.usersControllerFindCurrentUser()

    return new User({ ...data, role: data.role as Role })
  }

  async updateCurrentUserFullName(fullName: {
    firstName: string
    lastName: string
  }): Promise<void> {
    await this._reviewManagerService.api.usersControllerUpdateCurrentUserFullName(
      {
        firstName: fullName.firstName,
        lastName: fullName.lastName,
      },
    )
  }

  async enableUser(userId: string): Promise<void> {
    await this._reviewManagerService.api.usersControllerEnableUser({
      userId: userId,
    })
  }

  async edit(user: {
    userId: string
    firstName: string
    lastName: string
    role: Role
  }): Promise<void> {
    await this._reviewManagerService.api.usersControllerEditUser({
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      userId: user.userId,
    })
  }

  async disableUser(userId: string): Promise<void> {
    await this._reviewManagerService.api.usersControllerDisableUser({
      userId: userId,
    })
  }

  async getRecoveryLink(): Promise<string> {
    const { data } =
      await this._reviewManagerService.api.usersControllerGetRecoveryLink()
    return data
  }

  async acceptTermsOfService(version: string): Promise<void> {
    await this._reviewManagerService.api.usersControllerAcceptTerms(version)
  }
}
