<template>
  <Modal dismissible>
    <template v-if="user?.role !== Role.READ_ONLY" #activator="{ show }">
      <button
        class="bg-primary text-white font-bold py-3 px-6 rounded-lg inline-flex gap-2 items-center h-11 hover:bg-primary/90"
        @click="show"
      >
        <PlusIcon class="w-4 stroke-2" />

        <span>Create project</span>
      </button>
    </template>
    <template #content="{ hide }">
      <div class="w-[500px] px-8 py-4">
        <div class="flex justify-end items-end">
          <button class="" @click="hide">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
        <div class="space-y-3">
          <div class="flex flex-col justify-center items-center gap-2">
            <CreateProjectIcon class="w-10 h-10 text-primary" />
            <p class="text-black text-2xl font-bold leading-6 tracking[-0.4px]">
              Create project
            </p>
          </div>
          <div class="space-y-4">
            <TextInput
              ref="projectNameInput"
              v-model="projectName"
              label="Project name"
              placeholder="Enter project name"
              type="text"
              :rules="[(v) => !!v || 'Project name is required']"
              @enter="createProject(hide)"
            />

            <div class="flex justify-between gap-6">
              <button
                class="w-full bg-white text-primary border border-primary rounded-lg text-base font-medium py-3 hover:brightness-125"
                @click="hide"
              >
                Cancel
              </button>
              <button
                class="w-full bg-primary text-white rounded-lg text-base py-3 font-medium hover:brightness-125"
                @click="createProject(hide)"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import useProjects from '@app/views/Projects/use-projects'
import { ref } from 'vue'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { HttpException } from '@core/exceptions/http.exception'
import { Role } from '@core/domain/types/role.type'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import CreateProjectIcon from '@app/components/Icons/CreateProjectIcon.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { PlusIcon } from '@heroicons/vue/24/outline'

const { user } = injectStrict(AuthKey)
const projects = useProjects()
const snackbar = useSnackbar()
const loading = useLoading()
const projectName = ref('')
const projectNameInput = ref<InstanceType<typeof TextInput>>()

async function createProject(cb: () => void = () => {}) {
  if (!projectNameInput.value?.validate()) return

  try {
    loading.start()
    await projects.create({ name: projectName.value })

    snackbar.show(SnackbarState.SUCCESS, 'Project createed successfully')
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    projectName.value = ''
    loading.stop()
    cb()
  }
}
</script>
