<template>
  <Modal dismissible>
    <template #activator="{ show: showEditModal }">
      <button
        class="hover:brightness-150 flex items-center gap-1 leading-none p-2 hover:bg-black/20 w-full"
        @click.stop="showEditModal"
      >
        <PenIcon class="h-4 w-auto" />
        <p>Edit</p>
      </button>
    </template>
    <template #content="{ hide: hideEditModal }">
      <div
        class="p-6 w-[1000px] h-[90vh] overflow-auto dark:bg-[#103047] dark:text-white/70 space-y-4"
      >
        <p class="text-center uppercase w-full block tracking-wide font-bold">
          Study
        </p>

        <div class="px-2 flex flex-col gap-6">
          <TextInput
            v-model="internalMetaData.title"
            placeholder="Study title"
            label="Title"
          />
          <TextInput
            v-model="internalMetaData.doi"
            placeholder="Study DOI"
            label="DOI"
          />

          <TextInput
            v-model="internalMetaData.publishYear"
            placeholder="Year of publication"
            label="Year of publication"
          />

          <div
            class="bg-white border border-black/30 pt-4 pb-4 px-5 rounded-lg cursor-text space-y-4 flex flex-col"
          >
            <label class="text-sm text-primary font-medium pointer-events-none"
              >Authors
            </label>

            <div
              v-for="(author, i) in internalMetaData.authors"
              :key="'author-' + author"
              class="flex justify-between"
            >
              <TextInput
                v-model="internalMetaData.authors[i]"
                class="w-full"
                placeholder="Author Name"
              >
                <template #suffix>
                  <button
                    v-tooltip="'click to delete author'"
                    class="text-red-600"
                    @click="removeAuthor(internalMetaData.authors[i])"
                  >
                    <XCircleIcon class="w-5 h-5" />
                  </button>
                </template>
              </TextInput>
            </div>
            <button
              class="border border-primary rounded py-1 px-2 hover:brightness-150"
              @click="addAuthor()"
            >
              Add Author
            </button>
          </div>

          <TextInput
            v-model="internalMetaData.journal"
            placeholder="Study journal"
            label="Journal"
          />
          <TextInput
            v-model="internalMetaData.pagesNumber"
            placeholder="Study pages number"
            label="Pages number"
          />
          <TextInput
            v-model="internalMetaData.volumeNumber"
            placeholder="Study volume number"
            label="Volume number"
          />
          <TextInput
            v-model="internalMetaData.issueNumber"
            placeholder="Study issue number"
            label="Issue number"
          />

          <div
            class="bg-white border border-black/30 pt-4 pb-4 px-5 rounded-lg cursor-text space-y-4"
          >
            <label class="text-sm text-primary font-medium pointer-events-none">
              Abstract
            </label>
            <InlineTextInput
              v-model="internalMetaData.abstract"
              placeholder="Please Write Your Message"
              :multiline="true"
              class="w-full dark:text-white"
            />
          </div>

          <div class="flex justify-end gap-2 w-full py-2 right-0">
            <button
              class="text-red-700 border border-red-700 rounded w-24 hover:brightness-150"
              @click="hideEditModal"
            >
              Cancel
            </button>
            <FloatingMenu v-if="!isValid" trigger="hover" :offset="[0, 10]">
              <template #activator>
                <button
                  class="border cursor-not-allowed border-primary rounded py-1 px-2 w-24 hover:brightness-150"
                >
                  Save
                </button>
              </template>
              <template #content>
                <div
                  class="flex flex-col justify-end gap-2 bg-white w-full py-2 px-2 sticky bottom-0 right-0"
                >
                  <p v-if="internalMetaData.title === ''">Title is missing</p>

                  <p v-if="internalMetaData.abstract === ''">
                    Abstract is missing
                  </p>
                  <p
                    v-if="
                      internalMetaData.authors.every((author) => author === '')
                    "
                  >
                    author name is missing
                  </p>
                </div>
              </template>
            </FloatingMenu>
            <button
              v-else
              class="border border-primary rounded py-1 px-2 w-24 hover:brightness-150"
              @click="updateStudy(props.id, internalMetaData, hideEditModal)"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import Modal from '@app/components/Global/Modal/Modal.vue'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import useLoading from '@app/composables/use-loading'
import InlineTextInput from '@app/components/Global/Inputs/InlineTextInput.vue'
import { Id } from '@core/domain/types/id.type'
import { MetaData } from '@core/domain/types/meta-data.type'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import XCircleIcon from '@app/components/Icons/XCircleIcon.vue'
import PenIcon from '@app/components/Icons/PenIcon.vue'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'

const props = withDefaults(
  defineProps<{
    id: Id
    metaData: MetaData
  }>(),
  {},
)
function prepareMetaData(): MetaData {
  return props.metaData
}

const internalMetaData = ref<MetaData>(prepareMetaData())
const snackbar = useSnackbar()

const loading = useLoading()

const review = injectStrict(ReviewKey)

const isValid = computed(() => {
  const isTitleValid = internalMetaData.value.title !== ''
  const isAbstractValid = internalMetaData.value.abstract !== ''
  const isAuthorsValid = internalMetaData.value.authors.every(
    (author) => author !== '',
  )
  return isTitleValid && isAbstractValid && isAuthorsValid
})

function removeAuthor(authorName: string) {
  const authorNameIndex = internalMetaData.value.authors.findIndex(
    (author) => author === authorName,
  )
  internalMetaData.value?.authors.splice(authorNameIndex, 1)
}

function addAuthor() {
  internalMetaData.value.authors.push('')
}

async function updateStudy(stydyid: Id, data: MetaData, callback: () => void) {
  try {
    loading.start()
    await review.updateStudy(stydyid, {
      abstract: data.abstract,
      doi: data.doi,
      issueNumber: data.issueNumber,
      journal: data.journal,
      pagesNumber: data.pagesNumber,
      publishYear: data.publishYear,
      title: data.title,
      volumeNumber: data.volumeNumber,
      authors: [...data.authors],
    })
    snackbar.show(SnackbarState.SUCCESS, 'Study updated successfully')
    callback()
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    throw e
  } finally {
    loading.stop()
  }
}
</script>
