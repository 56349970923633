import { SnackbarState } from '@app/types'
import { readonly, ref } from 'vue'

const isSnackbarShown = ref(false)
const state = ref<SnackbarState>()
const content = ref<string>()
let timeout: ReturnType<typeof setTimeout>

export default () => {
  function show(snackbarState: SnackbarState, snackbarContent: string) {
    isSnackbarShown.value = false
    setTimeout(() => (isSnackbarShown.value = true), 0)
    state.value = snackbarState
    content.value = snackbarContent
    clearTimeout(timeout)
    timeout = setTimeout(() => (isSnackbarShown.value = false), 6000)
  }

  function hide() {
    isSnackbarShown.value = false
    clearTimeout(timeout)
  }

  function success(content: string) {
    show(SnackbarState.SUCCESS, content)
  }

  function error(content: string) {
    show(SnackbarState.ERROR, content)
  }

  return {
    isShown: readonly(isSnackbarShown),
    content: readonly(content),
    state: readonly(state),
    show,
    hide,
    success,
    error,
  }
}
