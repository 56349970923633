<template>
  <Modal dismissible>
    <template #activator="{ show }">
      <button
        class="bg-blue-100 text-blue-800 px-4 py-2 rounded-full whitespace-nowrap flex items-center gap-1"
        @click="areAllStudiesProcessed ? exportReport() : show()"
      >
        <DocumentTextIcon class="w-5" /> Report
        <ArrowDownTrayIcon class="w-4" />
      </button>
    </template>
    <template #content="{ hide }">
      <div class="sm:w-full sm:max-w-lg">
        <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg
                class="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <div class="mt-2">
                <p v-if="!areAllStudiesProcessed" class="text-sm text-gray-500">
                  Some studies are missing their appraisal or screening, are you
                  sure you would like to proceed with the export?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            @click=";[hide(), exportReport()]"
          >
            Proceed
          </button>
          <button
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            @click="hide"
          >
            Cancel
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { ReviewKey } from '@app/views/Review/use-review'
import { hideAll } from 'tippy.js'
import Modal from '@app/components/Global/Modal/Modal.vue'
import { injectStrict } from '@app/utils/injectStrict'
import useUsers from '@app/composables/use-users'
import { generateProtocol } from './utils/generate-protocol'
import { generatePrismaDiagram } from './utils/generate-prisma-diagram'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline'
import { DocumentTextIcon } from '@heroicons/vue/24/solid'
import useExport from './useExport'

const review = injectStrict(ReviewKey)
const { areAllStudiesProcessed } = useExport(review.entity.value)

const users = useUsers()
users.refresh()
const loading = useLoading()
const snackbar = useSnackbar()

async function exportReport(): Promise<void> {
  loading.start()
  try {
    const prismaDiagramImage = await generatePrismaDiagram(review.entity.value)

    const protocol = await generateProtocol(
      review.entity.value,
      review.entity.value.plan?.appraisalCriteria ?? [],
      review.currentAttributesStructure.value,
      prismaDiagramImage,
      review.inclusionCriteria.value!,
      false,
      true,
    )

    const downloadElement = document.createElement('a')
    downloadElement.style.display = 'none'
    downloadElement.href = window.URL.createObjectURL(new Blob([protocol]))
    downloadElement.download =
      review.entity.value?.project?.name +
      ' - ' +
      review.entity.value?.name +
      '.docx'
    document.body.appendChild(downloadElement)
    downloadElement.click()
    window.URL.revokeObjectURL(downloadElement.href)
    downloadElement.remove()
    hideAll()
    snackbar.success('Report exported successfully')
  } catch (error) {
    snackbar.error('Failed to export report')
    throw error
  } finally {
    loading.stop()
  }
}
</script>
