import { ref, readonly, Ref } from 'vue'

const expansionPanels: Ref<boolean>[] = []
export default () => {
  const index = expansionPanels.push(ref(false)) - 1

  const toggle = (): boolean => {
    const oldValue = expansionPanels[index]?.value
    expansionPanels.forEach((ep) => (ep.value = false))
    expansionPanels[index].value = !oldValue
    return expansionPanels[index].value
  }

  return {
    isShown: readonly(expansionPanels[index]),
    toggle,
  }
}
