<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      d="M32.9936 19.8663C32.7382 19.4636 32.3941 19.1256 31.9881 18.8785C31.5821 18.6315 31.1251 18.4819 30.6525 18.4414H30.3761C29.7378 18.473 29.1229 18.6933 28.6082 19.0749C28.0936 19.4564 27.7019 19.9823 27.4822 20.5868C26.9366 22.1146 26.0461 23.4939 24.8809 24.6157C23.6305 25.8307 22.1032 26.718 20.433 27.1997C18.7628 27.6815 17.0007 27.7429 15.3015 27.3787C13.6022 27.0145 12.0176 26.2358 10.6867 25.1109C9.35582 23.9859 8.31936 22.5492 7.66821 20.9267C7.01705 19.3042 6.77113 17.5454 6.95201 15.8048C7.13289 14.0642 7.73501 12.395 8.70561 10.9435C9.67621 9.49195 10.9856 8.30256 12.5189 7.47966C14.0522 6.65677 15.7625 6.2255 17.4998 6.22371C18.1541 6.22715 18.8068 6.28745 19.4508 6.40389C20.004 6.48661 20.5293 6.70248 20.9821 7.03319C21.435 7.3639 21.802 7.79972 22.052 8.30369C22.3447 9.23721 21.5805 10.269 20.8652 10.9896L19.6296 12.2507C18.9644 11.9324 18.2362 11.77 17.4998 11.7757C16.4052 11.7723 15.3388 12.1256 14.4599 12.783C13.5811 13.4404 12.9369 14.3665 12.6236 15.4231C12.3103 16.4797 12.3447 17.61 12.7217 18.6453C13.0986 19.6805 13.798 20.5651 14.7152 21.167C15.6324 21.7689 16.7183 22.0557 17.8107 21.9847C18.9031 21.9136 19.9433 21.4886 20.7761 20.7729C21.6089 20.0571 22.1894 19.0892 22.4311 18.0137C22.6728 16.9382 22.5627 15.8128 22.1171 14.8056L28.6203 8.25456C29.1135 7.66669 29.3632 6.91019 29.3178 6.14184C29.2724 5.37349 28.9353 4.65216 28.3763 4.12738C25.3548 1.44691 21.4609 -0.0218335 17.4348 0.000245351C14.6972 -0.00941071 11.9983 0.652004 9.571 1.92744C7.14371 3.20288 5.06089 5.05404 3.50222 7.32123C1.94354 9.58842 0.955819 12.2035 0.624238 14.941C0.292657 17.6785 0.627206 20.4563 1.59898 23.0345C2.57075 25.6127 4.15055 27.9141 6.20218 29.74C8.2538 31.566 10.7156 32.8617 13.3752 33.5155C16.0349 34.1692 18.8124 34.1613 21.4684 33.4925C24.1243 32.8237 26.5789 31.514 28.6203 29.6765L29.3356 29.0049C31.1032 27.2235 32.4578 25.0703 33.3025 22.6996C33.4736 22.2357 33.5348 21.738 33.4812 21.2461C33.4275 20.7542 33.2606 20.2817 32.9936 19.8663Z"
      fill="#2C3795"
    />
  </svg>
</template>
