<template>
  <div class="w-full relative">
    <div
      v-if="loading.isLoading.value"
      class="overflow-hidden absolute top-0 left-0 w-full h-1 z-50"
    >
      <div class="line absolute bg-white/20 h-1"></div>
      <div class="absolute bg-white h-1 inc"></div>
      <div class="absolute bg-white h-1 dec"></div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import useLoading from '../composables/use-loading'
const loading = useLoading()
</script>
