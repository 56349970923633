export class IncidentMetadata {
  title?: string
  eventType?: string
  eventDate?: string
  reportNumber?: string
  reportDate?: string
  url?: string
  summary?: string
  constructor(data: {
    title?: string
    eventType?: string
    eventDate?: string
    reportNumber?: string
    reportDate?: string
    url?: string
    summary?: string
  }) {
    this.title = data.title
    this.eventType = data.eventType
    this.eventDate = data.eventDate
    this.reportNumber = data.reportNumber
    this.reportDate = data.reportDate
    this.url = data.url
    this.summary = data.summary
  }
}
