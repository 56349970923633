import { computed, ref } from 'vue'
export enum ExecuteStep {
  Screening = 0,
  StudyDesign = 1,
  Appraisal = 2,
  Synthesis = 3,
}
const activeExecuteStep = ref(ExecuteStep.Screening)

export default () => {
  const isScreeningStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.Screening,
  )

  const isStudyDesignStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.StudyDesign,
  )

  const isAppraisalStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.Appraisal,
  )

  const isSynthesisStageActive = computed(
    () => activeExecuteStep.value === ExecuteStep.Synthesis,
  )

  function setActiveExecuteStep(step: ExecuteStep) {
    activeExecuteStep.value = step
  }

  return {
    isStudyDesignStageActive,
    isScreeningStageActive,
    isAppraisalStageActive,
    isSynthesisStageActive,
    activeExecuteStep,
    setActiveExecuteStep,
  }
}
