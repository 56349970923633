<template>
  <GenericAutoComplete
    v-model="selectedCslStyle"
    :items="cslStyleListItems"
    :item-options="{
      displayProperty: 'text',
      filterProperties: ['text'],
      valueProperty: 'value',
    }"
    placeholder="Select Citation style"
    @update:model-value="updateCslStyle"
  >
    <template #label>
      <label class="text-sm text-primary font-medium flex gap-2 items-center">
        <p class="pl-2 py-1.5 font-semibold text-lg text-primary">
          Citation style
        </p>
      </label>
    </template>
    <template #item="{ text }">
      {{ text }}
    </template>
  </GenericAutoComplete>
</template>

<script setup lang="ts">
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useCslStyles from '@app/composables/use-cslStyles'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { errorMessage } from '@app/utils/error-message'
import { HttpException } from '@core/exceptions/http.exception'
import { computed, ref } from 'vue'
import { ReviewKey } from '../use-review'
import { injectStrict } from '@app/utils/injectStrict'

const review = injectStrict(ReviewKey)
const project = review.entity.value.project!

const loading = useLoading()
const snackbar = useSnackbar()

const cslStyles = useCslStyles()
const selectedCslStyle = ref(project.cslStyle)

const cslStyleListItems = computed(() =>
  cslStyles.list.value
    .map((s) => {
      const text = s.charAt(0).toUpperCase() + s.replaceAll('-', ' ').slice(1)
      return { text: text.replaceAll('-', ' '), value: s }
    })
    .sort((a, b) => a.text.localeCompare(b.text)),
)

async function updateCslStyle(style?: unknown) {
  if (!style || typeof style !== 'string') return
  try {
    loading.start()
    selectedCslStyle.value = style
    await review.updateCslStyle(style)
    snackbar.show(SnackbarState.SUCCESS, 'Citation style updated')
  } catch (e) {
    const error = e as HttpException
    selectedCslStyle.value = project.cslStyle
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
